import React, { useEffect, useState } from 'react';
import { AiOutlineUpload } from 'react-icons/ai';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const TestimonialForm = () => {
    const user = useSelector((state) => state?.user?.user);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        designation: '',
        image: null
    });
    const [testimonials, setTestimonials] = useState([]);
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);

    const fetchTestimonials = async () => {
        try {
            const response = await fetch(`https://builder.biztoindia.in/api/items/${user?._id}`);
            if (response.ok) {
                const data = await response.json();
                console.log("fetchdata", data);
                setTestimonials(data);
            } else {
                throw new Error('Error fetching testimonials');
            }
        } catch (err) {
            setError('Error fetching testimonials');
            toast.error(err.message);
        }
    };

    useEffect(() => {
        if (user?._id) {
            fetchTestimonials();
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0]
        });
    };

    const handleEdit = (testimonial) => {
        setFormData({
            name: testimonial.name,
            description: testimonial.description,
            designation: testimonial.designation,
            image: null // Don't change the image unless uploading a new one
        });
        setEditId(testimonial._id);
        setIsEditing(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitData = new FormData();
        submitData.append('name', formData.name);
        submitData.append('description', formData.description);
        submitData.append('designation', formData.designation);
        if (formData.image) {
            submitData.append('image', formData.image);
        }

        try {
            let response;
            if (isEditing) {
                response = await fetch(`https://builder.biztoindia.in/api/items/${editId}`, {
                    credentials: 'include',
                    method: 'PUT',
                    body: submitData
                });
            } else {
                response = await fetch('https://builder.biztoindia.in/api/items', {
                    credentials: 'include',
                    method: 'POST',
                    body: submitData
                });
            }

            if (response.ok) {
                toast.success(isEditing ? 'Testimonial updated successfully!' : 'Testimonial uploaded successfully!');
                setFormData({ name: '', description: '', designation: '', image: null });
                setError('');
                fetchTestimonials(); // Refresh testimonials after successful submission
                setIsEditing(false); // Reset editing state
                setEditId(null); // Reset edit ID
            } else {
                throw new Error('Error uploading testimonial');
            }
        } catch (err) {
            setError('Error uploading testimonial');
            toast.error(err.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://builder.biztoindia.in/api/items/${id}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            const data= await response.json();
            // console.log("datataa",data);
            if (response.ok) {
                toast.success('Testimonial deleted successfully');
                setTestimonials(testimonials.filter((item) => item._id !== id));
            } else {
                throw new Error('Error deleting testimonial');
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <div className="flex flex-col md:flex-row">
            <div className="max-w-lg mx-auto p-5 bg-white rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold mb-6 text-center text-blue-500">
                    Share Your Experience!
                </h2>

                <p className="text-center text-gray-600 mb-6">
                    Let others hear about your amazing journey. Upload a testimonial, and feel free to add more.
                    Every voice matters, and we'd love to hear yours!
                </p>

                <Toaster position="top-right" reverseOrder={false} />

                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    {/* Name Input */}
                    <div className="mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Your Name"
                            required
                        />
                    </div>

                    {/* Description Input */}
                    <div className="mb-4">
                        <label className="block text-gray-700">Description</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Your testimonial"
                            required
                        />
                    </div>

                    {/* Designation Input */}
                    <div className="mb-4">
                        <label className="block text-gray-700">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Your Designation"
                            required
                        />
                    </div>

                    {/* Image Upload */}
                    <div className="mb-4">
                        <label className="block text-gray-700">Upload Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="w-full text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <AiOutlineUpload className="absolute text-gray-400 ml-2 mt-2" />
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded-md focus:outline-none"
                    >
                        {isEditing ? 'Update Testimonial' : 'Add Testimonial'}
                    </button>
                </form>

                {error && <p className="text-red-500 mt-4">{error}</p>}
            </div>

            {/* Testimonials List */}
            <div className="max-w-lg mx-auto mt-10 p-5">
                <h3 className="text-2xl font-bold mb-6 text-center">Testimonials</h3>
                {testimonials?.map((testimonial) => (
                    <div key={testimonial._id} className="border border-gray-300 rounded-lg p-4 bg-white shadow-md">
                        <img src={`https://builder.biztoindia.in/${testimonial.image}`} alt={testimonial.name} className="w-full h-32 object-cover mb-2 rounded-md" />
                        <h3 className="font-semibold text-lg">{testimonial.name}</h3>
                        <p className="text-gray-600">{testimonial.designation}</p>
                        <p className="text-gray-700 mt-2">
                            {testimonial.description.length > 20 
                                ? `${testimonial.description.substring(0, 20)}...` 
                                : testimonial.description}
                        </p>
                        <div className="flex justify-between mt-4">
                            <button 
                                onClick={() => handleDelete(testimonial?._id)} 
                                className="text-red-500 hover:underline"
                            >
                                Delete
                            </button>
                            <button 
                                onClick={() => handleEdit(testimonial)} // Trigger edit
                                className="text-blue-500 hover:underline"
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TestimonialForm;
