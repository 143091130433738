import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SummaryApi from '../common'
import VerticalCard from '../components/VerticalCard'
import { useSelector } from 'react-redux'
 
const SearchProduct = () => {
    const query = useLocation()
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(false)
    const user = useSelector(state => state?.user?.user);
    // console.log("query",query.search)

    const fetchProduct = async () => {
      setLoading(true);
  
      // Get the owner's ID from user (ensure the user is authenticated and available)
      const ownerId = user?._id; // Replace with actual user ID logic
  
      // Make a POST request to the server with the search query and owner ID in the body
      const response = await fetch(SummaryApi.searchProduct.url, {
          method: 'POST', // Change to POST for sending data in the body
          headers: {
              'Content-Type': 'application/json' // Ensure JSON format
          },
          body: JSON.stringify({
              q: query.search, // Include the search query in the body
              owner:  user?._id    // Include the ownerId securely in the body
          })
      });
  
      const dataResponse = await response.json();
      setLoading(false);
  
      // Update the data state with the response from the server
      setData(dataResponse.data);
  };
  
  useEffect(() => {
      fetchProduct(); // Call the function when the query changes
  }, [query]);
  
  return (
    <div className='container mx-auto p-4'>
      {
        loading && (
          <p className='text-lg text-center'>Loading ...</p>
        )
      }
 
      <p className='text-lg font-semibold my-3'>Search Results : {data?.length}</p>

      {
        data?.length === 0 && !loading && (
           <p className='bg-white text-lg text-center p-4'>No Data Found....</p>
        )
      }


      {
        data?.length !==0 && !loading && (
          <VerticalCard loading={ loading} data={data}/>
        )
      }

    </div>
  )
}

export default SearchProduct