import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";

const ShowGallery = () => {
  const [files, setFiles] = useState([]);
  const user = useSelector(state => state?.user?.user);

  // Fetch files from the API
  const fetchFiles = async () => {
    if (!user?._id) return; // Exit if no user ID

    try {
      const response = await fetch(` https://builder.biztoindia.in/api/files/${user._id}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setFiles(data);
      } else {
        setFiles([]); // Set empty array if data is not an array
      }
    } catch (error) {
      // Handle error (optional)
      // toast.error('Failed to load files');
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    fetchFiles(); // Load files on component mount
  }, [user?._id]);

  // Render different file types
  const renderFile = (file) => {
    const fileType = file.mimeType.split('/')[0];

    switch (fileType) {
      case 'image':
        return (
          <img
            src={`https://builder.biztoindia.in/${file.path}`}
            alt={file.originalName}
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        );
      case 'video':
        return (
          <video
            controls
            className="w-full h-full object-cover rounded-lg shadow-lg"
          >
            <source src={`https://builder.biztoindia.in/${file.path}`} type={file.mimeType} />
            Your browser does not support the video tag.
          </video>
        );
      case 'audio':
        return (
          <audio
            controls
            className="w-full h-full object-cover rounded-lg shadow-lg"
          >
            <source src={`https://builder.biztoindia.in/${file.path}`} type={file.mimeType} />
            Your browser does not support the audio element.
          </audio>
        );
      case 'application':
        return (
          <a
            href={`https://builder.biztoindia.in/${file.path}`}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full h-full flex items-center justify-center rounded-lg shadow-lg bg-gray-200 text-gray-700 hover:bg-gray-300"
          >
            <p className="text-center">View Document</p>
          </a>
        );
      default:
        return (
          <div className="w-full h-full flex items-center justify-center rounded-lg shadow-lg bg-gray-200 text-gray-700">
            <p>Unsupported file type</p>
          </div>
        );
    }
  };

  return (
    <> 
      <Helmet> 
        <title>Gallery</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
        <Toaster position="top-center" reverseOrder={false} />
        
        {/* Conditional rendering based on files */}
        {files.length === 0 ? (
          <div className="text-center text-gray-500">
            No images available
          </div>
        ) : (
          <div className={`grid ${files.length === 1 ? 'grid-cols-1' : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4'} gap-4 w-full`}>
            {files.map((file, index) => (
              <div
                key={index}
                className={`relative ${files.length === 1 ? 'w-full h-screen' : 'w-full h-48 md:h-64'}`}
              >
                {renderFile(file)}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ShowGallery;
