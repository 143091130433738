import React, { useState } from 'react';
import { toast,Toaster } from 'react-hot-toast';

const  AdvancedSeo = () => {
    const [domain, setDomain] = useState('');

    const handleDomainSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://builder.biztoindia.in/api/add-custom-domain', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',  // Automatically include cookies for session-based auth
                body: JSON.stringify({ domain })
            });

            const data = await response.json();

            if (response.ok) {
                toast.success(data.message || 'Custom domain added successfully');
                setDomain(''); // Clear the input after success
            } else {
                throw new Error(data.message || 'Failed to add custom domain');
            }
        } catch (error) {
            toast.error(error.message);
            // console.error('Error:', error);
        }
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-md">
            <Toaster/>
            <h2 className="text-2xl font-bold text-center mb-4">Hey ! Please Enter Your Domain</h2>
            <form onSubmit={handleDomainSubmit}>
                <label htmlFor="domain" className="block text-sm font-medium text-gray-700 mb-1">
                  Your Domain
                </label>
                <input
                    type="text"
                    id="domain"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    placeholder="e.g.,  https://biztoindia.com"
                    className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    required
                />
                <button
                    type="submit"
                    className="mt-4 w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                >
                    Advanced Seo
                </button>
            </form>
        </div>
    );
};

export default  AdvancedSeo;
