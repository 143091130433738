import React, { useState } from 'react';
import loginIcons from '../assest/signin.gif';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import bizto from "../assest/bizto.jpeg"
import { MdSupportAgent } from 'react-icons/md'
import { AiOutlineAppstore ,AiOutlineClose} from 'react-icons/ai';
import { FaPhoneAlt, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
 
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    const [data, setData] = useState({
        email: '',
        password: '',
        name: '',
        confirmPassword: '',
        number: '',
    });
    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (data.password === data.confirmPassword) {
            const response = await fetch(SummaryApi.signUP.url, {
                method: SummaryApi.signUP.method,
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(data),
            });

            const apiData = await response.json();
            if (apiData.success) {
                // toast.success(apiData.message);
                // navigate('/login');
                toast.info("Congratulations Your Account has been created Succefully , It will be activated after Verification.");
            } else {
                toast.error(apiData.message);
            }
        } else {
            toast.error('Password and Confirm Password do not match.');
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit1= async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch("https://builder.biztoindia.in/api/enquiry-builder", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
    
          const data = await response.json();
    //   console.log("Enquiry data",data);
          if (response.ok) {
            toast.success("Enquiry submitted successfully!");
            setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
            setIsModalOpen(false); // Close modal on success
          } else {
            toast.error(data.message || "Failed to submit enquiry");
          }
        } catch (error) {
          toast.error("Something went wrong. Please try again.");
        }
      };
  
    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-r from-purple-700 to-indigo-900 text-white -mt-16">
            {/* Fixed Header */}
            <header className="fixed top-0 left-0 right-0 z-50 flex justify-between items-center px-8 py-4 bg-white shadow-md">
                <div className="flex flex-col md:flex-row items-center md:space-x-4 text-center md:text-left">
                    <div className="w-16 h-16 overflow-hidden">
                        <img src={bizto} alt="BizTo India Logo" className="w-full h-full object-contain" />
                    </div>
                    <h1 className="hidden md:block text-3xl font-bold text-yellow-500">Bizto India</h1>
                </div>

                <div className="flex items-center space-x-4 sm:space-x-6">
                    <a href="tel:+9910443978">
                    <button className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <IoCall className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Call Us</span>
                    </button>
                    </a>
                    <a href="mailto:info@biztoindia.com" className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <MdEmail className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Email Us</span>
                    </a>
                </div>
            </header>


            {/* Main Content */}
            <main className="flex flex-1 flex-col md:flex-row mt-20">
                {/* Left Section */}
                <div className="w-full md:w-1/2 p-10 md:p-16 md:-mt-20 md:mb-10 flex flex-col justify-center space-y-6 animate__animated animate__fadeInLeft">
                   
                    <h2 className="text-4xl md:text-5xl font-extrabold">Create Your Own Online Presence</h2>
                    <p className="text-lg leading-relaxed">
                        Join us today and explore endless possibilities to grow your business with our comprehensive online solutions.
                    </p>
                    <div className="space-x-4">
                        <button
                            onClick={() => setIsModalOpen(true)} // Open Modal
                            className="bg-yellow-500 px-6 py-2 rounded-full hover:bg-yellow-600 transition-all duration-300 shadow-lg"
                        >
                            Enquire Now
                        </button>
                    </div>
                </div>
               
                {/* Right Section - Signup Form */}
                <div className="w-full md:w-1/2 p-8 md:p-10 flex items-center justify-center">
                    <div className="bg-white shadow-2xl rounded-lg p-8 w-full max-w-md transform transition-all hover:scale-105 duration-300">
                        
                        <div className="text-center mb-6">
                        <h2 className="text-xl md:text-3xl font-extrabold text-yellow-500">Join Our Platform</h2>
                    <p className="text-gray-600  ">
                        Create your free account and access premium tools to manage your business effortlessly.
                    </p>
                          
                        </div>

                        <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
                            <div className="grid">
                                <label className="text-gray-700">Name:</label>
                                <div className="bg-gray-100 p-3 rounded-lg shadow-md text-gray-600">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Enter your name"
                                        value={data.name}
                                        onChange={handleOnChange}
                                        className="w-full outline-none bg-transparent"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid">
                                <label className="text-gray-700">Phone Number:</label>
                                <div className="bg-gray-100 p-3 rounded-lg shadow-md text-gray-600">
                                    <input
                                        type="text"
                                        name="number"
                                        placeholder="Enter your phone number"
                                        value={data.number}
                                        onChange={handleOnChange}
                                        className="w-full outline-none bg-transparent"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid">
                                <label className="text-gray-700">Email:</label>
                                <div className="bg-gray-100 p-3 rounded-lg shadow-md text-gray-600">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={data.email}
                                        onChange={handleOnChange}
                                        className="w-full outline-none bg-transparent"
                                        required
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="text-gray-700">Password:</label>
                                <div className="bg-gray-100 p-3 rounded-lg shadow-md flex items-center text-gray-600">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        placeholder="Enter your password"
                                        value={data.password}
                                        onChange={handleOnChange}
                                        className="w-full outline-none bg-transparent"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="text-xl ml-2"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                            </div>

                            <div>
                                <label className="text-gray-700">Confirm Password:</label>
                                <div className="bg-gray-100 p-3 rounded-lg shadow-md flex items-center text-gray-600">
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name="confirmPassword"
                                        placeholder="Confirm your password"
                                        value={data.confirmPassword}
                                        onChange={handleOnChange}
                                        className="w-full outline-none bg-transparent"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="text-xl ml-2"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="bg-yellow-500 hover:bg-yellow-600 text-white py-3 rounded-full shadow-lg transition-all duration-300"
                            >
                                Sign Up
                            </button>
                        </form>

                        <p className="text-center mt-5 text-red-600">
                            Already have an account?{' '}
                            <Link to="/login" className="text-yellow-500 hover:underline">
                                Login
                            </Link>
                        </p>
                    </div>
                </div>
            </main>
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                  <h2 className="text-xl font-bold mb-4 text-yellow-500">Enquire Now</h2>
                  <form className="flex flex-col gap-4 text-gray-800" onSubmit={handleSubmit1}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Your Phone Number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      rows="4"
                      required
                    />
                    <button
                      type="submit"
                      className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition"
                    >
                      Submit
                    </button>
                  </form>
                  <button
                    onClick={() => setIsModalOpen(false)} // Close Modal
                    className="absolute top-2 right-2 text-red-500"
                  >
                    <AiOutlineClose className="text-xl" />
                  </button>
                </div>
              </div>
            )}
            {/* Footer */}
            <footer className="bg-white  bottom-0 left-0 right-0 py-4">
      

      <p className="text-center mt-2 text-yellow-500 text-sm">&copy; 2024 BizToIndia. All Rights Reserved.</p>
    </footer>
        </div>
    );
};

export default SignUp;
