// EnquiriesTable.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const EnquiriesTable = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector(state => state?.user?.user);

  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-enquiry/${user?._id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEnquiries(data.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch enquiries');
        setLoading(false);
      }
    };

    fetchEnquiries();
  }, [user?._id]);

  if (loading) return <p className="text-center text-gray-500">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Enquiries</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-md shadow-md">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="px-4 py-2 border-b">Name</th>
              <th className="px-4 py-2 border-b">Mobile</th>
              <th className="px-4 py-2 border-b">Email</th>
              <th className="px-4 py-2 border-b">Product Name</th>
              <th className="px-4 py-2 border-b">Date</th>
            </tr>
          </thead>
          <tbody>
            {enquiries?.map((enquiry) => (
              <tr key={enquiry._id} className="hover:bg-gray-100 transition-colors duration-200">
                {/* Show as a single column on small devices */}
                <td className="px-4 py-2 border-b sm:table-cell hidden">{enquiry.name}</td>
                <td className="px-4 py-2 border-b sm:table-cell hidden">
                  <a href={`tel:${enquiry.mobile}`} className="text-blue-600 hover:underline">
                    {enquiry.mobile}
                  </a>
                </td>
                <td className="px-4 py-2 border-b sm:table-cell hidden">
                  <a href={`mailto:${enquiry.email}`} className="text-blue-600 hover:underline">
                    {enquiry.email}
                  </a>
                </td>
                <td className="px-4 py-2 border-b sm:table-cell hidden">{enquiry?.product}</td>
                <td className="px-4 py-2 border-b sm:table-cell hidden">
                  {new Date(enquiry.createdAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false // 24-hour time format
                  })}
                </td>

                {/* On small devices, show all details in one column */}
                <td className="sm:hidden px-4 py-2 border-b">
                  <div className="flex flex-col">
                    <span className="font-bold">Name: </span> {enquiry.name}
                    <span className="font-bold">Mobile: </span>
                    <a href={`tel:${enquiry.mobile}`} className="text-blue-600 hover:underline">
                      {enquiry.mobile}
                    </a>
                    <span className="font-bold">Email: </span>
                    <a href={`mailto:${enquiry.email}`} className="text-blue-600 hover:underline">
                      {enquiry.email}
                    </a>
                    <span className="font-bold">Product: </span> {enquiry?.product}
                    <span className="font-bold">Date: </span>
                    {new Date(enquiry.createdAt).toLocaleString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: false // 24-hour time format
                    })}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EnquiriesTable;
