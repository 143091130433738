import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const HeaderColorPallet = () => {
  const [primaryColor, setPrimaryColor] = useState('#ff0000'); // Default red
  const [secondaryColor, setSecondaryColor] = useState('#00ff00'); // Default green
  const [backgroundColor, setBackgroundColor] = useState('#0000ff'); // Default blue
  const user = useSelector(state => state?.user?.user);
 



  
  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${user?._id}`);
        const data = await response.json();
        
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // console.error('Error fetching header colors:', error);
      }
    };
    fetchHeaderColor();
  }, [user?._id]);

  // Handlers for each color change
  const handlePrimaryColorChange = (color) => setPrimaryColor(color.hex);
  const handleSecondaryColorChange = (color) => setSecondaryColor(color.hex);
  const handleBackgroundColorChange = (color) => setBackgroundColor(color.hex);

  // Function to save color settings

// console.log("Primary color", primaryColor);
// console.log("Secondary color", secondaryColor);
// console.log("Background color", backgroundColor);

  const saveColors = async () => {
    try {
      const response = await fetch('https://builder.biztoindia.in/api/update-body-color', {
        method: 'PUT',
        credentials:"include",
        headers: {
            "content-type": "application/json",
          },
        body: JSON.stringify({
          primaryColor,
          secondaryColor,
          backgroundColor,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        // console.log('Colors updated successfully:', result);
        // toast.success("Color Updated Succefully");
        toast.success(result.message);
      } else {
        const error = await response.json();
        // console.error('Error updating colors:', error);
      }
    } catch (error) {
    //   console.error('Error saving colors:', error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row gap-8">
        
        {/* Primary Color Picker */}
        <div className="w-full md:w-1/3">
          <h4 className="text-lg font-semibold mb-2">Heading Color</h4>
          <SketchPicker color={primaryColor} onChange={handlePrimaryColorChange} />
          {/* Display selected color */}
          <div
            className="mt-4 p-4 text-white rounded-lg w-20"
            style={{ backgroundColor: primaryColor }}
          >
            {primaryColor}
          </div>
        </div>

        {/* Secondary Color Picker */}
        <div className="w-full md:w-1/3">
          <h4 className="text-lg font-semibold mb-2">Sub Heading Color </h4>
          <SketchPicker color={secondaryColor} onChange={handleSecondaryColorChange} />
          {/* Display selected color */}
          <div
            className="mt-4 p-4 text-white rounded-lg w-20"
            style={{ backgroundColor: secondaryColor }}
          >
            {secondaryColor}
          </div>
        </div>

        {/* Background Color Picker */}
        <div className="w-full md:w-1/3">
          <h4 className="text-lg font-semibold mb-2">Background Color:</h4>
          <SketchPicker color={backgroundColor} onChange={handleBackgroundColorChange} />
          {/* Display selected color */}
          <div
            className="mt-4 p-4 text-white rounded-lg w-20"
            style={{ backgroundColor: backgroundColor }}
          >
            {backgroundColor}
          </div>
        </div>

      </div>
      {/* Save Button */}
      <button
        className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={saveColors}
      >
        Save Colors
      </button>
    </div>
  );
};

export default HeaderColorPallet;
