import React, { useState } from "react";
import { MdModeEditOutline, MdDelete } from "react-icons/md"; // Import delete icon
import AdminEditProduct from "./AdminEditProduct";
import displayINRCurrency from "../helpers/displayCurrency";
import { Helmet } from "react-helmet";

const AdminProductCard = ({ data, fetchdata }) => {
  const [editProduct, setEditProduct] = useState(false);

  // Fallbacks for SEO fields if not provided
  const metaTitle = data.metaTitle || `${data.productName} - Product Details`;
  const metaDescription = data.metaDescription || `Buy ${data.productName} by ${data.brandName} for just ${displayINRCurrency(data.sellingPrice)}. ${data.description}`;
  const metaKeywords = data.metaKeywords || `${data.productName}, ${data.brandName}, buy ${data.category}, ${data.category} products`;
console.log("plllldata",data);
  // Function to handle product deletion
  const handleDelete = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/product/${data._id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete the product');
      }

      // Optionally refetch data after deletion
      fetchdata();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <div className="bg-white p-4 rounded">
      {/* React Helmet for SEO */}
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>

      <div className="w-40">
        <div className="w-32 h-32 flex justify-center items-center">
          <img
          src={`https://builder.biztoindia.in/${data?.productImage[0]}`}
            
            className="mx-auto object-fill h-full"
            alt={data.productName}
          />
        </div>
        <h1 className="text-ellipsis line-clamp-2">{data.productName}</h1>

        <div className="flex items-center justify-between">
          <p className="font-semibold">
            {displayINRCurrency(data.sellingPrice)}
          </p>

          <div className="flex items-center space-x-2">
            <div
              className="w-fit p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer"
              onClick={() => setEditProduct(true)}
            >
              <MdModeEditOutline />
            </div>

            <div
              className="w-fit p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer"
              onClick={handleDelete}
            >
              <MdDelete />
            </div>
          </div>
        </div>
      </div>

      {editProduct && (
        <AdminEditProduct
          productData={data}
          onClose={() => setEditProduct(false)}
          fetchdata={fetchdata}
        />
      )}
    </div>
  );
};

export default AdminProductCard;
