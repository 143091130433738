import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { AiOutlineApi } from 'react-icons/ai';

const ThirdPartyApi = () => {
  const [formData, setFormData] = useState({
    webhookurl: '',
    platformName: 'IndiaMART',
    apikey: '',
    otp: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Your form submission logic
    toast.success("Feature coming soon! Thank you for your interest.");
  };

  return (
    <div className="p-6 max-w-lg mx-auto font-mono bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-lg shadow-md text-white">
      <Toaster />
      {/* Notice Banner */}
      <div className="text-center mb-6">
        <div className="bg-yellow-500 text-white font-bold py-2 px-4 rounded mb-4">
          🚧 This feature is under development. Functionality may be limited.
        </div>
        <AiOutlineApi size={50} className="mx-auto text-white mb-2" />
        <h2 className="text-3xl font-bold">Integrate Third-Party API</h2>
        <p className="text-sm mt-2">
          Connect your business with trusted third-party platforms like IndiaMART, TradeIndia, Housing, and more. Automate your operations by setting up API integrations!
        </p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow-md text-gray-700">
        
        {/* Platform Name Dropdown */}
        <div>
          <label className="block text-gray-800 font-semibold mb-1">Platform Name</label>
          <select
            name="platformName"
            value={formData.platformName}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          >
            <option value="IndiaMART">IndiaMART</option>
            <option value="TradeIndia">TradeIndia</option>
            <option value="Housing">Housing</option>
            <option value="99acres">99acres</option>
            <option value="Justdial">Justdial</option>
            <option value="Sulekha">Sulekha</option>
          </select>
        </div>

        {/* Webhook URL Field */}
        <div>
          <label className="block text-gray-800 font-semibold mb-1">Webhook URL</label>
          <input
            type="url"
            name="webhookurl"
            value={formData.webhookurl}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            placeholder="Enter Webhook URL"
          />
        </div>

        {/* API Key Field */}
        <div>
          <label className="block text-gray-800 font-semibold mb-1">API Key</label>
          <input
            type="text"
            name="apikey"
            value={formData.apikey}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            placeholder="Enter API Key"
          />
        </div>

        {/* OTP Field */}
        <div>
          <label className="block text-gray-800 font-semibold mb-1">OTP</label>
          <input
            type="text"
            name="otp"
            value={formData.otp}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            placeholder="Enter OTP"
          />
        </div>

        <button
          type="submit"
          disabled
          className="w-full bg-blue-600 text-white font-semibold p-2 rounded hover:bg-blue-700 transition duration-200 transform hover:scale-105 opacity-50 cursor-not-allowed"
        >
          Integrate API
        </button>
      </form>
    </div>
  );
};

export default ThirdPartyApi;
