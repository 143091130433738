import React, { useState, useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import SummaryApi from "../common";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from 'quill';
import QuillTable from 'quill-table';

// Register the table module
Quill.register('modules/table', QuillTable);

const UploadProduct = ({ onClose, fetchData }) => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    description: "",
    price: "",
    sellingPrice: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    shortdescription: "",
    isPriceVisible: false,
    priceUnit: "Per Piece", // Default unit
  });

  const [newCategory, setNewCategory] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${SummaryApi.allcategory.url}/${user?._id}`,
          {
            method: SummaryApi.allcategory.method,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.success) {
          setCategories(responseData.categories);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        // toast.error(
        //   "Failed to fetch categories. Please check the console for details."
        // );
      }
    };

    fetchCategories();
  }, [user?._id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnChange2 = (e) => {
    const { name, type, checked } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : prevData[name],
    }));
  };

  const handlePriceUnitChange = (e) => {
    setData((prev) => ({
      ...prev,
      priceUnit: e.target.value,
      price: prev.price.split(" ")[0] + " " + e.target.value, // Update the price with the selected unit
    }));
  };

  const handleProductImageChange = (e) => {
    const files = Array.from(e.target.files);
    setData((prev) => ({
      ...prev,
      productImage: files,
    }));
  };

  const handleCategoryImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCategoryImage(file);
    }
  };

  const handleDeleteProductImage = (index) => {
    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);

    setData((prev) => ({
      ...prev,
      productImage: newProductImage,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData to handle file uploads
    const formData = new FormData();

    // Append form fields
    formData.append('productName', data.productName);
    formData.append('brandName', data.brandName);
    formData.append('category', newCategory || data.category);
    formData.append('description', data.description);
    formData.append('price', data.price);
    formData.append('sellingPrice', data.sellingPrice);
    formData.append('metaTitle', data.metaTitle);
    formData.append('metaDescription', data.metaDescription);
    formData.append('metaKeywords', data.metaKeywords);
    formData.append('shortdescription', data.shortdescription);
    formData.append('isPriceVisible', data.isPriceVisible);
    formData.append('priceUnit', data.priceUnit);

    // Append product images
    data.productImage.forEach((file) => {
      formData.append('productImages', file);
    });

    // Append category image if a new category is being added
    if (newCategory && categoryImage) {
      formData.append('categoryImage', categoryImage);
    }

    try {
      const response = await fetch(SummaryApi.uploadProduct.url, {
        method: SummaryApi.uploadProduct.method,
        credentials: "include",
        body: formData, // Send FormData
      });

      const responseData = await response.json();

      if (responseData.success) {
        toast.success(responseData?.message);
        onClose();
        fetchData();
      } else {
        toast.error(responseData?.message);
      }
    } catch (error) {
      // toast.error("Failed to upload product. Please try again.");
      // console.error("Error uploading product:", error);
    }
  };

  const handleDescriptionChange = (content) => {
    setData((prev) => ({
      ...prev,
      description: content,
    }));
  };

  const handleAddCategory = async () => {
    if (newCategory) {
      try {
        // Prepare the form data including the category name and image
        const categoryData = new FormData();
        categoryData.append('name', newCategory);
        if (categoryImage) {
          categoryData.append('image', categoryImage); // Include category image file
        }

        const response = await fetch(SummaryApi.createCateogory.url, {
          method: SummaryApi.createCateogory.method,
          credentials: "include",
          body: categoryData,
        });

        const responseData = await response.json();

        if (responseData.success) {
          setCategories((prev) => [
            ...prev,
            { value: newCategory, label: newCategory },
          ]);
          setData((prev) => ({ ...prev, category: newCategory }));
          setNewCategory("");
          setCategoryImage(null); // Reset the category image state
          toast.success(responseData.message);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        // toast.error(
        //   "Failed to add category. Please check the console for details."
        // );
        // console.error("Error adding category:", error);
      }
    }
  };

  return (
    <div className="fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center">
      <div className="bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden">
        <div className="flex justify-between items-center pb-3">
          <h2 className="font-bold  md:text-lg text-sm">Upload Product</h2>
          <div
            className="w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer"
            onClick={onClose}
          >
            <CgClose />
          </div>
        </div>

        <form
          className="grid p-4 gap-2 overflow-y-scroll h-full pb-5"
          onSubmit={handleSubmit}
          encType="multipart/form-data" // Important for file uploads
        >
          {/* Product Name */}
          <label htmlFor="productName">Product Name :</label>
          <input
            type="text"
            id="productName"
            placeholder="Enter product name"
            name="productName"
            value={data.productName}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
            required
          />

          {/* Brand Name */}
          <label htmlFor="brandName" className="mt-3">
            Brand Name :
          </label>
          <input
            type="text"
            id="brandName"
            placeholder="Enter brand name"
            value={data.brandName}
            name="brandName"
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
            required
          />

          {/* Category */}
          <label htmlFor="category" className="mt-3">
            Category :
          </label>
          <select
            required
            value={data.category}
            name="category"
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
          >
            <option value="">Select Category</option>

            {categories?.map((el, index) => (
              <option value={el.value} key={el.value + index}>
                {el.label}
              </option>
            ))}
          </select>

          {/* New Category */}
          <label htmlFor="newCategory" className="mt-3">
            New Category :
          </label>
          <input
            type="text"
            id="newCategory"
            placeholder="Enter new category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            className="p-2 bg-slate-100 border rounded"
          />

          {/* Category Image */}
          <label htmlFor="categoryImage" className="mt-3">
            Category Image :
          </label>
          <label htmlFor="uploadCategoryImageInput">
            <div className="p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer">
              <div className="text-slate-500 flex justify-center items-center flex-col gap-2">
                <span className="text-4xl">
                  <FaCloudUploadAlt />
                </span>
                <p className="text-sm">Upload Category Image</p>
                <input
                  type="file"
                  id="uploadCategoryImageInput"
                  className="hidden"
                  onChange={handleCategoryImageChange}
                  accept="image/*"
                />
              </div>
            </div>
          </label>
          {categoryImage && (
            <div className="mt-2">
              <img
                src={URL.createObjectURL(categoryImage)}
                alt="Category"
                className="w-32 h-32 object-cover"
              />
            </div>
          )}
          <button
            type="button"
            className="mt-2 p-2 bg-green-500 text-white rounded"
            onClick={handleAddCategory}
          >
            Add Category
          </button>

          {/* Short Description */}
          <label htmlFor="shortdescription" className="mt-3">
            Short Description :
          </label>
          <input
            id="shortdescription"
            placeholder="Enter short description (max 350 characters)"
            name="shortdescription"
            value={data.shortdescription}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
            rows={3}
            maxLength={350}
          />
          <div className="text-right text-sm text-gray-500">
            {350 - data.shortdescription.length} characters remaining
          </div>

          {/* Description */}
          <label htmlFor="description" className="mt-3">
            Description :
          </label>
          
          <ReactQuill
            value={data.description}
            onChange={handleDescriptionChange}
            modules={quillModules} // Use the extended toolbar
            theme="snow"
            
          />

          {/* Price */}
          <label htmlFor="price" style={{marginTop:"20%"}}>
            Price :
          </label>
          <input
            type="text"
            id="price"
            placeholder="Enter product price"
            name="price"
            value={data.price}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
            required
          />

          {/* Price Unit */}
          <label htmlFor="priceUnit" className="mt-3">
            Price Unit :
          </label>
          <select
            id="priceUnit"
            value={data.priceUnit}
            onChange={handlePriceUnitChange}
            className="p-2 bg-slate-100 border rounded"
          >
            <option value="Per Piece">Per Piece</option>
            <option value="Per ml">Per ml</option>
            <option value="Per sq ft">Per sq ft</option>
            <option value="Per meter">Per meter</option>
            <option value="Per year">Per year</option>
          </select>

          {/* Price Visibility */}
          <label htmlFor="isPriceVisible" className="mt-3 flex items-center gap-2 text-gray-700">
            <span className="font-medium">Price Visibility:</span>
            <input
              type="checkbox"
              id="isPriceVisible"
              name="isPriceVisible"
              checked={data.isPriceVisible}
              onChange={handleOnChange2}
              className="w-5 h-5 text-blue-600 bg-gray-100 border-2 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
            />
          </label>

          {/* Selling Price */}
          <label htmlFor="sellingPrice" className="mt-3">
            Selling Price :
          </label>
          <input
            type="text"
            id="sellingPrice"
            placeholder="Enter selling price"
            name="sellingPrice"
            value={data.sellingPrice}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
          />

          {/* Meta Title */}
          <label htmlFor="metaTitle" className="mt-3">
            Meta Title :
          </label>
          <input
            type="text"
            id="metaTitle"
            placeholder="Enter meta title (max 65 characters)"
            maxLength={65}
            name="metaTitle"
            value={data.metaTitle}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded w-full"
          />
          <div className="text-right text-sm text-gray-500">
            {65 - data.metaTitle.length} characters remaining
          </div>

          {/* Meta Description */}
          <label htmlFor="metaDescription" className="mt-3">
            Meta Description :
          </label>
          <input
            id="metaDescription"
            placeholder="Enter meta description (max 200 characters)"
            name="metaDescription"
            value={data.metaDescription}
            maxLength={200}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
            rows={4}
          />
          <div className="text-right text-sm text-gray-500">
            {200 - data.metaDescription.length} characters remaining
          </div>

          {/* Meta Keywords */}
          <label htmlFor="metaKeywords" className="mt-3">
            Meta Keywords :
          </label>
          <input
            type="text"
            id="metaKeywords"
            placeholder="Enter meta keywords"
            name="metaKeywords"
            value={data.metaKeywords}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
          />

          {/* Product Images */}
          <label htmlFor="productImage" className="mt-3">
            Product Images :
          </label>
          <label htmlFor="uploadProductImageInput">
            <div className="p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer">
              <div className="text-slate-500 flex justify-center items-center flex-col gap-2">
                <span className="text-4xl">
                  <FaCloudUploadAlt />
                </span>
                <p className="text-sm">Upload Product Images</p>
                <input
                  type="file"
                  id="uploadProductImageInput"
                  className="hidden"
                  onChange={handleProductImageChange}
                  multiple
                  accept="image/*"
                />
              </div>
            </div>
          </label>

          {data?.productImage?.length > 0 && (
            <div className="mt-2 flex gap-2 flex-wrap">
              {data?.productImage?.map((file, index) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Product"
                    className="w-32 h-32 object-cover"
                  />
                  <button
                    type="button"
                    onClick={() => handleDeleteProductImage(index)}
                    className="absolute top-0 right-0 text-red-500 bg-white rounded-full p-1"
                  >
                    <MdDelete />
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 p-2 bg-blue-500 text-white rounded"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    [{ size: ["small", "medium", "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link", "image", "video"], // Added image and video options
    ["clean"], // Remove formatting
    [{ table: 'insert-table' }],
  ],
};

export default UploadProduct;
