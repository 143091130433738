import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
 
const CategoryList = () => {
    const [categoryProduct,setCategoryProduct] = useState([])
    const [loading,setLoading] = useState(false)
    const user = useSelector(state => state?.user?.user);
    const categoryLoading = new Array(13).fill(null)

    const fetchCategoryProduct = async() =>{
        setLoading(true)
        const response = await fetch(`${SummaryApi.allcategory.url}/${user?._id}`)
        const dataResponse = await response.json();
        //  dataResponse.data.map((val,index)=>{
        //     (val.category,index)
        //  })
         console.log("helllllll",dataResponse)
        // callhere api 
        setLoading(false)
        setCategoryProduct(dataResponse?.categories)
    }

    useEffect(()=>{
        fetchCategoryProduct()
    },[user?._id])

  return (
    <div className='container mx-auto p-4'>
           <div className='flex items-center gap-4 justify-between overflow-scroll scrollbar-none'>
            {

                loading ? (
                    categoryLoading?.map((el,index)=>{
                            return(
                                <div className='h-16 w-16 md:w-20 md:h-20 rounded-full overflow-hidden bg-slate-200 animate-pulse' key={"categoryLoading"+index}>
                                </div>
                            )
                    })  
                ) :
                (
                    categoryProduct?.map((product,index)=>{
                        // ("product",product)
                        return(
                            <Link to={"/product-category?category="+product?.label} className='cursor-pointer' key={product?.category}>
                                <div className='w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden p-4 bg-slate-200 flex items-center justify-center'>
                                    <img src={`https://builder.biztoindia.in/${product?.image}`} alt={product?.category} className='h-full object-scale-down mix-blend-multiply hover:scale-125 transition-all'/>
                                </div>
                                <p className='text-center text-sm md:text-base capitalize'>{product?.label}</p>
                            </Link>
                        )
                    })
                )
            }
           </div>
    </div>
  )
}

export default CategoryList