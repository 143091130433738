import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import { toast } from 'react-toastify'
import moment from 'moment'
import { MdModeEdit } from "react-icons/md";
import ChangeUserRole from '../components/ChangeUserRole';

const AllUsers = () => {
    const [allUser, setAllUsers] = useState([])  // State to store all users
    const [searchEmail, setSearchEmail] = useState("")  // State to store the search query
    const [filteredUsers, setFilteredUsers] = useState([])   
    const [openUpdateRole, setOpenUpdateRole] = useState(false)
    const [updateUserDetails, setUpdateUserDetails] = useState({
        email: "",
        name: "",
        role: "",
        _id: ""
    })

    const fetchAllUsers = async () => {
        const fetchData = await fetch(SummaryApi.allUser.url, {
            method: SummaryApi.allUser.method,
            credentials: 'include'
        })

        const dataResponse = await fetchData.json()

        if (dataResponse.success) {
            setAllUsers(dataResponse.data)
            setFilteredUsers(dataResponse.data)  
        }

        if (dataResponse.error) {
            toast.error(dataResponse.message)
        }
    }

    useEffect(() => {
        fetchAllUsers()
    }, [])

    useEffect(() => {
        if (searchEmail === "") {
            setFilteredUsers(allUser)  // If no search term, show all users
        } else {
            const filtered = allUser.filter(user => user.email.toLowerCase().includes(searchEmail.toLowerCase()))
            setFilteredUsers(filtered)  // Filter users by email
        }
    }, [searchEmail, allUser])

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success("User ID copied to clipboard!")
        }).catch((err) => {
            toast.error("Failed to copy User ID!")
        })
    }

    return (
        <div className='bg-white pb-4'>
            {/* Search Bar */}
            <div className='mb-4'>
                <input
                    type="text"
                    placeholder="Search by email"
                    className="w-full p-2 border rounded-md"
                    value={searchEmail}
                    onChange={(e) => setSearchEmail(e.target.value)}
                />
            </div>

            {/* Table displaying all users */}
            <table className='w-full userTable'>
                <thead>
                    <tr className='bg-black text-white'>
                        <th>Sr.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Number</th>
                        <th>Role</th>
                        <th>Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredUsers.map((el, index) => {
                            return (
                                <tr key={el._id}>
                                    <td>{index + 1}</td>
                                    <td>{el?.name}</td>
                                    <td>{el?.email}</td>
                                    <td>{el?.number}</td>
                                    <td>{el?.role}</td>
                                    <td>{moment(el?.createdAt).format('LL')}</td>
                                    <td>
                                        <button className='bg-green-100 p-2 rounded-full cursor-pointer hover:bg-green-500 hover:text-white'
                                            onClick={() => {
                                                setUpdateUserDetails(el)
                                                setOpenUpdateRole(true)
                                            }}
                                        >
                                            <MdModeEdit />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {/* New table displaying user ID and email with Copy button */}
            <table className='w-full userTable mt-6'>
                <thead>
                    <tr className='bg-black text-white'>
                        <th>Sr.</th>
                        <th>User ID</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredUsers.map((el, index) => {
                            return (
                                <tr key={el._id}>
                                    <td>{index + 1}</td>
                                    <td>{el?._id}</td>
                                    <td>{el?.email}</td>
                                    <td>
                                        <button className='bg-blue-100 p-2 rounded-full cursor-pointer hover:bg-blue-500 hover:text-white'
                                            onClick={() => copyToClipboard(el?._id)}
                                        >
                                            Copy User Id
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {/* Modal for updating user role */}
            {
                openUpdateRole && (
                    <ChangeUserRole
                        onClose={() => setOpenUpdateRole(false)}
                        name={updateUserDetails.name}
                        email={updateUserDetails.email}
                        role={updateUserDetails.role}
                        userId={updateUserDetails._id}
                        callFunc={fetchAllUsers}
                    />
                )
            }
        </div>
    )
}

export default AllUsers;
