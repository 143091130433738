import React, { useState, useEffect } from 'react';
import { FaUpload, FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const BannerUpdate = () => {
  const [desktopImages, setDesktopImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(` https://builder.biztoindia.in/api/get-banner/${user?._id}`);
        const data = await response.json();
        if (response.ok) {
          setDesktopImages(data.data[0]?.desktopImages || []);
          setMobileImages(data.data[0]?.mobileImages || []);
        } else {
          toast.error(data.message || 'Failed to fetch images.');
        }
      } catch (error) {
        toast.error('Error fetching images.');
        console.error(error);
      }
    };
    fetchImages();
  }, [user?._id]);

  const handleFileChange = (e, setImage) => {
    const newFiles = Array.from(e.target.files).map((file) => ({
      id: Math.random().toString(36).substr(2, 9), // Temporary ID for new images
      file,
      previewUrl: URL.createObjectURL(file),
      isNew: true,
    }));

    setImage((prev) => [...prev, ...newFiles]);
  };

  const removeSelectedImage = (img, setImage) => {
    setImage((prev) => prev.filter((image) => image.id !== img.id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newDesktopImages = desktopImages.filter((img) => img.isNew);
    const newMobileImages = mobileImages.filter((img) => img.isNew);

    if (!newDesktopImages.length && !desktopImages.some((img) => !img.isNew)) {
      toast.error('Please upload at least one desktop image.');
      return;
    }

    if (!newMobileImages.length && !mobileImages.some((img) => !img.isNew)) {
      toast.error('Please upload at least one mobile image.');
      return;
    }

    const formData = new FormData();
    newDesktopImages.forEach((img) => formData.append('desktopImages', img.file));
    newMobileImages.forEach((img) => formData.append('mobileImages', img.file));

    try {
      const response = await fetch('https://builder.biztoindia.in/api/upload-banner', {
        credentials: 'include',
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        toast.success('Images uploaded successfully.');
        setDesktopImages((prev) => prev.map((img) => ({ ...img, isNew: false })));
        setMobileImages((prev) => prev.map((img) => ({ ...img, isNew: false })));
      } else {
        toast.error(data.message || 'Failed to upload images.');
      }
    } catch (error) {
      toast.error('Error uploading images.');
      console.error(error);
    }
  };

  const handleDeleteImage = async (imageId, type) => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/delete-image/${imageId}`, {
        credentials: 'include',
        method: 'DELETE',
      });

      const data = await response.json();
      if (response.ok) {
        toast.success('Image deleted successfully.');
        if (type === 'desktop') {
          setDesktopImages((prev) => prev.filter((img) => img.id !== imageId));
        } else {
          setMobileImages((prev) => prev.filter((img) => img.id !== imageId));
        }
        setShowModal(false);
      } else {
        toast.error(data.message || 'Failed to delete image.');
      }
    } catch (error) {
      toast.error('Error deleting image.');
      console.error(error);
    }
  };

  const renderImage = (img, type) => (
    <div key={img.id} className="relative h-32 w-full rounded-lg overflow-hidden shadow-md">
      <img
        src={img.previewUrl || `https://builder.biztoindia.in/${img.url}`}
        alt="Banner"
        className="object-cover w-full h-full"
      />
      <button
        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
        onClick={() => {
          if (img.isNew) {
            removeSelectedImage(img, type === 'desktop' ? setDesktopImages : setMobileImages);
          } else {
            setShowModal(true);
            setImageToDelete({ id: img.id, type });
          }
        }}
      >
        <FaTrash />
      </button>
    </div>
  );

  const confirmDelete = () => {
    if (imageToDelete) {
      handleDeleteImage(imageToDelete.id, imageToDelete.type);
    }
  };

  return (
    <div className="p-8 max-w-xl mx-auto bg-white shadow-lg rounded-lg border border-gray-300 mt-12">

      <h2 className="text-2xl font-bold text-center mb-6">Update Your Banners</h2>
      <p className="text-gray-700 text-sm leading-relaxed p-4">
  For optimal display, desktop banner images should have a resolution of 
  <span className="font-semibold"> 1920x700 pixels</span> to ensure they fit large screens 
  without distortion or pixelation. Mobile images, on the other hand, should be uploaded 
  in a <span className="font-semibold">square format (1:1 aspect ratio) </span>
</p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <label className="cursor-pointer flex items-center bg-indigo-600 text-white px-4 py-2 rounded-md">
            <FaUpload className="mr-2" /> Select Desktop Images
            <input
              type="file"
              multiple
              onChange={(e) => handleFileChange(e, setDesktopImages)}
              className="hidden"
            />
          </label>
          <div className="grid grid-cols-2 gap-4">
            {desktopImages.map((img) => renderImage(img, 'desktop'))}
          </div>
        </div>

        <div className="space-y-4">
          <label className="cursor-pointer flex items-center bg-indigo-600 text-white px-4 py-2 rounded-md">
            <FaUpload className="mr-2" /> Select Mobile Images
            <input
              type="file"
              multiple
              onChange={(e) => handleFileChange(e, setMobileImages)}
              className="hidden"
            />
          </label>
          <div className="grid grid-cols-2 gap-4">
            {mobileImages.map((img) => renderImage(img, 'mobile'))}
          </div>
        </div>

        <button type="submit" className="w-full py-3 bg-blue-500 text-white rounded-lg">
          Upload Images
        </button>
      </form>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <h3>Are you sure you want to delete this image?</h3>
            <div className="flex justify-end mt-4">
              <button onClick={confirmDelete} className="bg-red-500 text-white px-4 py-2 mr-2">
                Yes
              </button>
              <button onClick={() => setShowModal(false)} className="bg-gray-300 px-4 py-2">
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BannerUpdate;
