import React from 'react'
import bizto from "../assest/bizto.jpeg"
import { FaEye, FaEyeSlash, FaPhoneAlt, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';
import { AiOutlineAppstore, AiOutlineClose } from 'react-icons/ai'; 
import temp from "../assest/temp.png"
import { Link } from 'react-router-dom';
import { CiLogin } from "react-icons/ci";
const Templates = () => {
  return (
 
        <div className="min-h-screen flex flex-col bg-gradient-to-r from-purple-700 to-indigo-900 text-white -mt-16">
        <header className="fixed top-0 left-0 right-0 z-50 flex justify-between items-center px-8 py-4 bg-white shadow-md">
                <div className="flex flex-col md:flex-row items-center md:space-x-4 text-center md:text-left">
                    <div className="w-16 h-16 overflow-hidden">
                        <img src={bizto} alt="BizTo India Logo" className="w-full h-full object-contain" />
                    </div>
                    <h1 className="hidden md:block text-3xl font-bold text-yellow-500">Bizto India</h1>
                </div>

                <div className="flex items-center space-x-4 sm:space-x-6">
                    <Link to="/login"> 
                    <button className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <CiLogin className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Back To Login </span>
                    </button>
                    </Link>
                    <button className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <MdSupportAgent className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Support</span>
                    </button>
                </div>
            </header>
            <main className="flex flex-1 flex-col md:flex-row mt-20">
                <div className="w-full md:w-1/2 p-10 md:p-16 flex flex-col justify-center space-y-6 animate__animated animate__fadeInLeft">
                    <h1>All Templates</h1>
                </div>

                <div className="w-full md:w-1/2 p-8 md:p-10 flex items-center justify-center">
                   <img src={temp}/>
                     
                </div>
            </main>

            <footer className="bg-white  bottom-0 left-0 right-0 py-4">
      <div className="flex justify-center space-x-8 items-center">
        <Link to="/help" className="flex items-center space-x-2 text-yellow-500 hover:text-yellow-400 transition">
          <FaInfoCircle />
          <span>Help</span>
        </Link>

        <a href="tel:+9910443978" className="flex items-center space-x-2 text-yellow-500 hover:text-yellow-400 transition">
          <FaPhoneAlt />
          <span>Call Us</span>
        </a>

        <a href="mailto:info@biztoindia.com" className="flex items-center space-x-2 text-yellow-500 hover:text-yellow-400 transition">
          <FaEnvelope />
          <span>Email Us</span>
        </a>
      </div>

      <p className="text-center mt-2 text-yellow-500 text-sm">&copy; 2024 BizToIndia. All Rights Reserved.</p>
    </footer>

      </div>
  
  )
}

export default Templates