import React, { useState, useContext } from 'react';
import loginIcons from '../assest/signin.gif';
import { FaEye, FaEyeSlash, FaPhoneAlt, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';
import { AiOutlineAppstore, AiOutlineClose } from 'react-icons/ai'; // Import the close icon
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Context from '../context';
import bizto from "../assest/bizto.jpeg";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({ email: '', password: '' });
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
    const navigate = useNavigate();
    const { fetchUserDetails, fetchUserAddToCart } = useContext(Context);

    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
  
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataResponse = await fetch(SummaryApi.signIn.url, {
            method: SummaryApi.signIn.method,
            credentials: 'include',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(data),
        });
        const dataApi = await dataResponse.json();
        if (dataApi.success) {
            toast.success(dataApi.message);
            navigate('/admin');
            fetchUserDetails();
            fetchUserAddToCart();
        } else {
            toast.error(dataApi.message);
        }
    };


    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit1= async (e) => {
      e.preventDefault();
  
      try {
        const response = await fetch("https://builder.biztoindia.in/api/enquiry-builder", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
  
        const data = await response.json();
    console.log("Enquiry data",data);
        if (response.ok) {
          toast.success("Enquiry submitted successfully!");
          setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
          setIsModalOpen(false); // Close modal on success
        } else {
          toast.error(data.message || "Failed to submit enquiry");
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      }
    };

    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-r from-purple-700 to-indigo-900 text-white -mt-16">
            {/* Fixed Header */}
            <header className="fixed top-0 left-0 right-0 z-50 flex justify-between items-center px-8 py-4 bg-white shadow-md">
                <div className="flex flex-col md:flex-row items-center md:space-x-4 text-center md:text-left">
                    <div className="w-16 h-16 overflow-hidden">
                        <img src={bizto} alt="BizTo India Logo" className="w-full h-full object-contain" />
                    </div>
                    <h1 className="hidden md:block text-3xl font-bold text-yellow-500">Bizto India</h1>
                </div>

                <div className="flex items-center space-x-4 sm:space-x-6">
                    <a href="tel:+9910443978">
                    <button className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <IoCall className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Call Us</span>
                    </button>
                    </a>
                    <a href="mailto:info@biztoindia.com" className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <MdEmail className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Email Us</span>
                    </a>
                </div>
            </header>

            <main className="flex flex-1 flex-col md:flex-row mt-20">
                <div className="w-full md:w-1/2 p-10 md:p-16 flex flex-col justify-center space-y-6 animate__animated animate__fadeInLeft">
                    <h2 className="text-4xl md:text-5xl font-extrabold">Create Your Own Online Presence</h2>
                    <p className="text-lg leading-relaxed">
                        Join us today and explore endless possibilities to grow your business with our comprehensive online solutions.
                    </p>
                    <div className="space-x-4">
                        <button
                            onClick={() => setIsModalOpen(true)} // Open Modal
                            className="bg-yellow-500 px-6 py-2 rounded-full hover:bg-yellow-600 transition-all duration-300 shadow-lg"
                        >
                            Enquire Now
                        </button>
                    </div>
                </div>

                <div className="w-full md:w-1/2 p-8 md:p-10 flex items-center justify-center">
  <div className="bg-white shadow-2xl rounded-lg p-12 w-full max-w-md transform transition-all hover:scale-105 duration-300">
    <div className="text-center mb-6">
      <p className="text-gray-600">
        Login to manage your account and explore amazing features!
      </p>
    </div>

    <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
      <div className="grid">
        <label className="text-gray-700">Email:</label>
        <div className="bg-gray-100 p-3 rounded-lg text-gray-600 shadow-md">
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={data.email}
            onChange={handleOnChange}
            className="w-full outline-none bg-transparent"
          />
        </div>
      </div>

      <div>
        <label className="text-gray-700">Password:</label>
        <div className="bg-gray-100 p-3 rounded-lg shadow-md text-gray-600 flex items-center">
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Enter your password"
            value={data.password}
            onChange={handleOnChange}
            className="w-full outline-none bg-transparent"
          />
          <button
            type="button"
            className="text-xl ml-2"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <Link
          to="/forgot-password"
          className="text-red-600 hover:underline block mt-2 text-right"
        >
          Forgot password?
        </Link>
      </div>

      <button
        type="submit"
        className="bg-yellow-500 hover:bg-yellow-600 text-white py-3 rounded-full shadow-lg transition-all duration-300"
      >
        Login
      </button>

      <div className="text-center mt-4">
        <p className="text-red-500">
          Don't have an account?{' '}
          <Link
            to="/sign-up"
            className="text-yellow-500 hover:underline font-semibold"
          >
            Create one
          </Link>
        </p>
      </div>
    </form>
  </div>
</div>

            </main>

            {/* Enquiry Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                  <h2 className="text-xl font-bold mb-4 text-yellow-500">Enquire Now</h2>
                  <form className="flex flex-col gap-4 text-gray-800" onSubmit={handleSubmit1}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Your Phone Number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      rows="4"
                      required
                    />
                    <button
                      type="submit"
                      className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition"
                    >
                      Submit
                    </button>
                  </form>
                  <button
                    onClick={() => setIsModalOpen(false)} // Close Modal
                    className="absolute top-2 right-2 text-red-500"
                  >
                    <AiOutlineClose className="text-xl" />
                  </button>
                </div>
              </div>
            )}

<footer className="bg-white  bottom-0 left-0 right-0 py-4">
    

      <p className="text-center mt-2 text-yellow-600 text-sm">&copy; 2024 BizToIndia. All Rights Reserved.</p>
    </footer>
        </div>
    );
};

export default Login;
