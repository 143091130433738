import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ROLE from "../common/role";
import { setUserDetails } from "../store/userSlice";
import SummaryApi from "../common";
import { toast } from "react-toastify";
import { FaBars, FaTimes } from "react-icons/fa"; // For the menu icon
import {Helmet} from "react-helmet";
const  SuperAdmin = () => {
  const [localLoading, setLocalLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for mobile menu

  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLocalLoading(true);
        const dataResponse = await fetch(SummaryApi.current_user.url, {
          method: SummaryApi.current_user.method,
          credentials: "include",
        });

        const dataApi = await dataResponse.json();

        if (dataApi.success) {
          dispatch(setUserDetails(dataApi.data));
        }
      } catch (error) {
        toast.error("Failed to fetch user details");
      } finally {
        setLocalLoading(false);
      }
    };

    fetchUserDetails();
  }, [dispatch]);

  

  const handleLogout = async () => {
    try {
      const fetchData = await fetch(SummaryApi.logout_user.url, {
        method: SummaryApi.logout_user.method,
        credentials: "include",
      });

      const data = await fetchData.json();

      if (data.success) {
        toast.success(data.message);
        dispatch(setUserDetails(null));
        navigate("/");
      }

      if (data.error) {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Logout failed. Please try again.");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar on mobile
  };

  if (localLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="grid  grid-cols-1 md:grid-cols-[auto,1fr] min-h-screen relative overflow-hidden">
      {/* Menu button for mobile devices */}
      <button
        className="absolute top-4 left-4 sm:hidden z-20"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? (
          <FaTimes className="w-8 h-8 text-gray-800" />
        ) : (
          <FaBars className="w-8 h-8 text-gray-800" />
        )}
      </button>

      {/* Sidebar */}
      <aside
        className={`bg-white   w-full md:w-64 lg:w-80 p-4 shadow-md md:block h-screen fixed md:relative z-10 transform transition-transform duration-300 ease-in-out
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full md:translate-x-0"}`}
      >
        <div className="flex flex-col h-full">
          <nav className="space-y-6 flex-grow overflow-y-auto">
            <Link
              to="all-users"
             style={{marginTop:"25%"}}
              className="block px-4 py-2  rounded-lg hover:bg-gray-200 transition duration-300"
            >
              All Users
            </Link>
            <Link
              to="super-admin-enquirey"
            
              className="block px-4 py-2  rounded-lg hover:bg-gray-200 transition duration-300"
            >
              All Enquiries 
            </Link>
             
            <Link
              to="super-email-integreation"
            
              className="block px-4 py-2  rounded-lg hover:bg-gray-200 transition duration-300"
            >
              Email integreation
            </Link>
             
             
             
            

            
          </nav>

          {/* Logout Button */}
          <div className="w-full  ">
            <button
              onClick={handleLogout}
              className="w-full px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg transition duration-300"
            >
              Logout
            </button>
          </div>
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 bg-gray-100 transition-all duration-300 " >
        <div style={{marginTop:"6%"}}  >
        <Outlet />
        </div>
       
      </main>
    </div>
  );
};

export default  SuperAdmin;
