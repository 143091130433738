import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const FooterColorPallet = () => {
  const [primaryColor, setPrimaryColor] = useState(''); // Default red
  const [secondaryColor, setSecondaryColor] = useState(''); // Default green
  const [backgroundColor, setBackgroundColor] = useState(''); // Default blue
  const [thirdColor, setThirdColor] = useState(''); // Default third color
  const user = useSelector((state) => state?.user?.user); // Assuming user is stored in Redux
 
  // Fetch current color settings on component mount
  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(` https://builder.biztoindia.in/api/get-footer-color/${user?._id}`);
        const data = await response.json();
       console.log("dataheder",data)
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
          setThirdColor(data.data.thirdColor); // Set third color
        }
      } catch (error) {
        // console.error('Error fetching header colors:', error);
      }
    };

    
      fetchHeaderColor();
  
  }, [user?._id]);

  // Handlers for each color change
  const handlePrimaryColorChange = (color) => setPrimaryColor(color.hex);
  const handleSecondaryColorChange = (color) => setSecondaryColor(color.hex);
  const handleBackgroundColorChange = (color) => setBackgroundColor(color.hex);
  const handleThirdColorChange = (color) => setThirdColor(color.hex);

  // Function to save color settings
  const saveColors = async () => {
    try {
      const response = await fetch(' https://builder.biztoindia.in/api/update-footer-color', {
        method: 'PUT',
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          primaryColor,
          secondaryColor,
          backgroundColor,
          thirdColor, // Send third color in the request
          owner: user?._id, // Send the user ID as the owner
        }),
      });

      if (response.ok) {
        const result = await response.json();
        // toast.success("Colors updated successfully");
        toast.success(result.message);
      } else {
        const error = await response.json();
        // console.error('Error updating colors:', error);
      }
    } catch (error) {
      // console.error('Error saving colors:', error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row gap-8">
        
        {/* Primary Color Picker */}
        <div className="w-full md:w-1/4">
          <h4 className="text-lg font-semibold mb-2"> Text Color:</h4>
          <SketchPicker color={primaryColor} onChange={handlePrimaryColorChange} />
          <div
            className="mt-4 p-4 text-white rounded-lg w-20"
            style={{ backgroundColor: primaryColor }}
          >
            {primaryColor}
          </div>
        </div>

        {/* Secondary Color Picker */}
        <div className="w-full md:w-1/4">
          <h4 className="text-lg font-semibold mb-2">Search Box Text Color:</h4>
          <SketchPicker color={secondaryColor} onChange={handleSecondaryColorChange} />
          <div
            className="mt-4 p-4 text-white rounded-lg w-20"
            style={{ backgroundColor: secondaryColor }}
          >
            {secondaryColor}
          </div>
        </div>

        {/* Background Color Picker */}
        <div className="w-full md:w-1/4">
          <h4 className="text-lg font-semibold mb-2">Background Color</h4>
          <SketchPicker color={backgroundColor} onChange={handleBackgroundColorChange} />
          <div
            className="mt-4 p-4 text-white rounded-lg w-20"
            style={{ backgroundColor: backgroundColor }}
          >
            {backgroundColor}
          </div>
        </div>

        {/* Third Color Picker */}
        <div className="w-full md:w-1/4">
          <h4 className="text-lg font-semibold mb-2">Search Icon Background Color:</h4>
          <SketchPicker color={thirdColor} onChange={handleThirdColorChange} />
          <div
            className="mt-4 p-4 text-white rounded-lg w-20"
            style={{ backgroundColor: thirdColor }}
          >
            {thirdColor}
          </div>
        </div>

      </div>

      {/* Save Button */}
      <button
        className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={saveColors}
      >
        Save Colors
      </button>
    </div>
  );
};

export default  FooterColorPallet;
