import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS
import toast, { Toaster } from 'react-hot-toast'; // Import react-hot-toast
import { useSelector } from 'react-redux';
import { FaEdit, FaTrashAlt } from 'react-icons/fa'; 

const CreateBlog = () => {
  const [editorHtml, setEditorHtml] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [blogs, setBlogs] = useState([]);
  const [editingBlog, setEditingBlog] = useState(null);
  const user = useSelector(state => state?.user?.user);

  useEffect(() => {
    fetchBlogs(); // Fetch blogs when the component mounts
  }, [user?._id]);

  const fetchBlogs = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/blogss/${user?._id}`); // Adjust the endpoint as needed
      if (response.ok) {
        const data = await response.json();
        setBlogs(data);
        toast.success("Blogs loaded successfully!"); // Show success notification
      } else {
        throw new Error('Failed to fetch blogs');
      }
    } catch (error) {
      toast.error("Error fetching blogs!"); // Show error notification
    }
  };

  const handleChange = (value) => {
    setEditorHtml(value);
  };

  const handleFileChange = (event) => {
    setThumbnail(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('content', editorHtml);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }

    try {
      const response = editingBlog
        ? await fetch(`https://builder.biztoindia.in/api/blog/edit/${editingBlog._id}`, {
            credentials: 'include',
            method: 'PUT',
            body: formData,
          })
        : await fetch('https://builder.biztoindia.in/api/add', {
            credentials: 'include',
            method: 'POST',
            body: formData,
          });

      const data = await response.text();
      console.log("lkjdfk",data);
      if (response.ok) {
        toast.success(editingBlog ? 'Blog post updated successfully!' : 'Blog post saved successfully!', {
          position: 'top-right',
          duration: 4000,
        });
        resetForm();
        fetchBlogs(); // Fetch updated blogs after submit
      } else {
        throw new Error(editingBlog ? 'Failed to update blog post' : 'Failed to save blog post');
      }
    } catch (error) {
      toast.error('Error saving blog post. Please try again later.', {
        position: 'top-right',
        duration: 4000,
      });
    }
  };

  const handleEdit = (blog) => {
    setTitle(blog.title);
    setCategory(blog.category);
    setEditorHtml(blog.content);
    setMetaTitle(blog.metaTitle);
    setMetaDescription(blog.metaDescription);
    setMetaKeywords(blog.metaKeywords);
    setEditingBlog(blog);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
    if (!confirmDelete) return;

    console.log("Attempting to delete blog with ID:", id); // Log ID being deleted
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/blog/delete/${id}`, {
        credentials: "include",
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json', // Add this line
        },
      });

      const data = await response.text(); // Fetch the response
      // console.log("Delete response:", data); // Log the response from the server

      if (response.ok) {
        toast.success('Blog post deleted successfully!', {
          position: 'top-right',
          duration: 4000,
        });
        fetchBlogs();  // Refresh blogs after deletion
      } else {
        throw new Error('Failed to delete blog post');
      }
    } catch (error) {
      toast.error('Error deleting blog post. Please try again later.', {
        position: 'top-right',
        duration: 4000,
      });
      console.error("Error deleting blog post:", error); // Log the error
    }
  };



  const handleDeleteComments = async (blogId, commentId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Comment?');
    if (!confirmDelete) return;
  
    // console.log("Attempting to delete comment with ID:", commentId); // Log comment ID being deleted
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/${blogId}/comments/${commentId}`, {
        credentials: "include",
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json', // Add this line
        },
      });
  
      const data = await response.json(); // Fetch the response
  
      // Uncomment to log the response from the server
      console.log("Delete response:", data);
  
      if (response.ok) {
        toast.success('Comment deleted successfully!', {
          position: 'top-right',
          duration: 4000,
        });
        fetchBlogs();  // Refresh blogs after deletion
      } else {
        throw new Error('Failed to delete Comment');
      }
    } catch (error) {
      toast.error('Error deleting comment of this blog. Please try again later.', {
        position: 'top-right',
        duration: 4000,
      });
      console.error("Error deleting comment:", error); // Log the error
    }
  };
  



  const resetForm = () => {
    setTitle('');
    setCategory('');
    setEditorHtml('');
    setThumbnail(null);
    setMetaTitle('');
    setMetaDescription('');
    setMetaKeywords('');
    setEditingBlog(null);
  };

  const formatDateToIST = (date) => {
    const options = {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone: 'Asia/Kolkata',
    };
    return new Date(date).toLocaleDateString('en-IN', options);
  };

  return (
    <>
      <div className='flex md:flex-row flex-col '>
        <div className="container mx-auto p-4 flex-8">
          <h1 className="text-3xl font-bold mb-6 text-center text-gray-700">
            {editingBlog ? 'Edit Blog Post' : 'Create a New Blog Post'}
          </h1>
          <div className="bg-white p-6 lg:p-10 rounded-lg shadow-lg max-w-3xl mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your blog title"
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <input
                  id="category"
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="E.g., Technology, Lifestyle"
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="thumbnail" className="block text-sm font-medium text-gray-700">
                  Thumbnail Image
                </label>
                <input
                  id="thumbnail"
                  type="file"
                  onChange={handleFileChange}
                  className="mt-1 block w-full"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="metaTitle" className="block text-sm font-medium text-gray-700">
                  Meta Title
                </label>
                <input
                  id="metaTitle"
                  type="text"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="SEO-friendly title for search engines"
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="metaDescription" className="block text-sm font-medium text-gray-700">
                  Meta Description
                </label>
                <textarea
                  id="metaDescription"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                  placeholder="Write a short description for SEO"
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="metaKeywords" className="block text-sm font-medium text-gray-700">
                  Meta Keywords
                </label>
                <input
                  id="metaKeywords"
                  type="text"
                  value={metaKeywords}
                  onChange={(e) => setMetaKeywords(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Comma-separated keywords"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700">
                  Content
                </label>
                <ReactQuill
                  value={editorHtml}
                  onChange={handleChange}
                  modules={quillModules}
                  className="h-64 mt-1"
                   
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 transition duration-300"
                style={{marginTop:"15%"}}
              >
                {editingBlog ? 'Update Blog Post' : 'Create Blog Post'}
              </button>
            </form>
          </div>
        </div>
        <div className="container mx-auto p-4 flex-2">
  <h1 className="text-2xl font-bold mb-6 text-center text-gray-700">Blog List</h1>
  <div className="overflow-y-auto h-full">
    {blogs.map((blog) => (
      <div key={blog._id} className="bg-gray-100 p-4 rounded-lg mb-4 shadow-md">
        <h2 className="text-xl font-bold">{blog.title}</h2>
        <p className="text-gray-600">{formatDateToIST(blog.createdAt)}</p>
        <div className="flex justify-between mt-4">
          <button onClick={() => handleDelete(blog._id)} className="text-red-500 hover:underline">
            <FaTrashAlt /> 
          </button>
        </div>
        {/* Comments Section */}
        <div>
          <h4 className="text-lg font-semibold mb-2">Associated Comments</h4>
          {blog.comments.length > 0 ? (
            blog.comments.map((comment) => (
              <div key={comment._id} className="bg-gray-200 p-2 rounded-lg mb-2 flex justify-between items-center">
                <p className="text-gray-700">{comment.user}</p>
                <div className="flex items-center">
                  <button 
                    onClick={() =>  handleDeleteComments(blog._id, comment._id)} 
                    className="text-red-500 hover:text-red-700 transition duration-200"
                    aria-label="Delete comment"
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No comments yet.</p>
          )}
        </div>
      </div>
    ))}
  </div>
</div>

      </div>
      <Toaster />
    </>
  );
};

export default CreateBlog;
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    [{ size: ["small", "medium", "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link", "image", "video"], // Added image and video options
    ["clean"], // Remove formatting
    
  ],
};
