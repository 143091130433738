import React, { useEffect, useState } from 'react';
import { CgClose } from "react-icons/cg";
import productCategory from '../helpers/productCategory';
import { FaCloudUploadAlt } from "react-icons/fa";
import uploadImage from '../helpers/uploadImage';
import DisplayImage from './DisplayImage';
import { MdDelete } from "react-icons/md";
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from 'quill';
import QuillTable from 'quill-table';

// Register the table module
Quill.register('modules/table', QuillTable);

const AdminEditProduct = ({
    onClose,
    productData,
    fetchdata
  }) => {
  const [data, setData] = useState({
    ...productData,
    productName: productData?.productName,
    brandName: productData?.brandName,
    category: productData?.category,
    productImage: productData?.productImage || [],
    description: productData?.description,
    price: productData?.price,
    sellingPrice: productData?.sellingPrice,
    isPriceVisible: productData?.isPriceVisible || false,
    metaDescription: productData?.metaDescription,
    metaKeywords: productData?.metaKeywords,
    metaTitle: productData?.metaTitle,
    shortdescription: productData?.shortdescription
  });

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState("");
  const [categories, setCategories] = useState([]);
  const user = useSelector(state => state?.user?.user);

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  };

   
  const handleProductImageChange = (e) => {
    const files = Array.from(e.target.files);
    setData((prev) => ({
      ...prev,
      productImage: files,
    }));
  };
  const handleDeleteProductImage = (index) => {
    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);

    setData((prev) => ({
      ...prev,
      productImage: newProductImage,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(SummaryApi.updateProduct.url, {
      method: SummaryApi.updateProduct.method,
      credentials: 'include',
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(data)
    });

    const responseData = await response.json();

    if (responseData.success) {
      toast.success(responseData?.message);
      onClose();
      fetchdata();
    }

    if (responseData.error) {
      toast.error(responseData?.message);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${SummaryApi.allcategory.url}/${user?._id}`, {
          method: SummaryApi.allcategory.method,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        if (responseData.success) {
          setCategories(responseData.categories);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        toast.error("Failed to fetch categories.");
      }
    };

    fetchCategories();
  }, [user?._id]);


  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 md:top-0 top-20 p-4 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Edit Product</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
          <label htmlFor='productName'>Product Name :</label>
          <input 
            type='text' 
            id='productName' 
            name='productName'
            value={data.productName} 
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='brandName' className='mt-3'>Brand Name :</label>
          <input 
            type='text' 
            id='brandName' 
            name='brandName'
            value={data.brandName} 
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='category' className='mt-3'>Category :</label>
          <select value={data.category} name='category' onChange={handleOnChange} className='p-2 bg-slate-100 border rounded' required>
            <option value="">Select Category</option>
            {categories?.map((el, index) => (
              <option value={el.value} key={el.value + index}>{el.label}</option>
            ))}
          </select>

     
          <label htmlFor="productImage" className="mt-3">
            Product Images :
          </label>
          <label htmlFor="uploadProductImageInput">
            <div className="p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer">
              <div className="text-slate-500 flex justify-center items-center flex-col gap-2">
                <span className="text-4xl">
                  <FaCloudUploadAlt />
                </span>
                <p className="text-sm">Upload Product Images</p>
                <input
                  type="file"
                  id="uploadProductImageInput"
                  className="hidden"
                  onChange={handleProductImageChange}
                  multiple
                  accept="image/*"
                />
              </div>
            </div>
          </label>
         
          {data?.productImage?.length > 0 && (
            <div className="mt-2 flex gap-2 flex-wrap">
              {data?.productImage?.map((file, index) => (
                <div key={index} className="relative">
                  <img
                    src={`https://builder.biztoindia.in/${file}`}
                    alt="Product"
                    className="w-32 h-32 object-cover"
                  />
                  <button
                    type="button"
                    onClick={() => handleDeleteProductImage(index)}
                    className="absolute top-0 right-0 text-red-500 bg-white rounded-full p-1"
                  >
                    <MdDelete />
                  </button>
                </div>
              ))}
            </div>
          )}


          <label htmlFor='price' className='mt-3'>Price :</label>
          <input 
            type='text' 
            id='price' 
            name='price'
            value={data.price} 
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='sellingPrice' className='mt-3'>Selling Price :</label>
          <input 
            type='text' 
            id='sellingPrice' 
            name='sellingPrice'
            value={data.sellingPrice} 
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='isPriceVisible' className='mt-3'>Price Visibility:</label>
          <input 
            type='checkbox' 
            id='isPriceVisible' 
            name='isPriceVisible'
            checked={data.isPriceVisible}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='metaTitle' className='mt-3'>Meta Title :</label>
          <input 
            type='text' 
            id='metaTitle' 
            name='metaTitle'
            value={data.metaTitle} 
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='metaDescription' className='mt-3'>Meta Description :</label>
          <input 
            type='text' 
            id='metaDescription' 
            name='metaDescription'
            value={data.metaDescription} 
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='metaKeywords' className='mt-3'>Meta Keywords :</label>
          <input 
            type='text' 
            id='metaKeywords' 
            name='metaKeywords'
            value={data.metaKeywords} 
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

         
<label htmlFor='description' className='mt-3'>Description :</label>
          <ReactQuill
            theme="snow"
            value={data.description}
            onChange={(content) => setData((prev) => ({ ...prev, description: content }))}
            className='bg-slate-100 border rounded h-40'
            modules={quillModules}
          />

<label htmlFor="shortdescription"   className="mt-[70%] sm:mt-[30%]">
            Short Description :
          </label>
          <input
            id="shortdescription"
            placeholder="Enter short description (max 350 characters)"
            name="shortdescription"
            value={data.shortdescription}
            onChange={handleOnChange}
            className="p-2 bg-slate-100 border rounded"
            rows={3}
            maxLength={350}
          />
          <div className="text-right text-sm text-gray-500">
            {350 - data.shortdescription.length} characters remaining
          </div>


          
          <button className='bg-blue-500 hover:bg-blue-600 text-white rounded py-2 mb-7 mt-2 w-fit px-5 ml-auto'>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default AdminEditProduct;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    [{ size: ["small", "medium", "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link", "image", "video"], // Added image and video options
    ["clean"], // Remove formatting
    ["table"], // Added table option
  ],
};