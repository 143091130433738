import React, { useState, useEffect } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FaCloudUploadAlt } from 'react-icons/fa';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import uploadImage from '../helpers/uploadImage';

const AllCategoryUpdate = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [editCategoryImage, setEditCategoryImage] = useState(null);
  const user = useSelector(state => state?.user?.user);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${SummaryApi.allcategory.url}/${user?._id}`, {
          method: SummaryApi.allcategory.method,
          credentials: 'include',
          headers: { "Content-Type": "application/json" }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        // console.log("jjjjjjjjjjjjj",responseData)
        if (responseData.success) {
          setCategories(responseData.categories);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        // toast.error("You don't have any existing categories");
      }
    };

    fetchCategories();
  }, [user?._id]);

  const handleDeleteCategory = async (categoryId) => {
    try {
      const response = await fetch(`${SummaryApi.deleteCategory.url}/${categoryId}`, {
        method: SummaryApi.deleteCategory.method,
        credentials: 'include',
        headers: { "Content-Type": "application/json" }
      });
      const responseData = await response.json();
      if (responseData.success) {
        setCategories(categories.filter(category => category._id !== categoryId));
        toast.success(responseData.message);
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      // toast.error("Failed to delete category. Please check the console for details.");
    }
  };

  const handleCategoryImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setCategoryImage(file);
    }
  };

  const handleEditCategoryImageUpload = async (e) => {
 
    const file = e.target.files[0];
    if (file) {
      setEditCategoryImage(file);
    }
  };

  const handleAddCategory = async () => {
    if (newCategory) {
      try {
        const formData = new FormData();
        formData.append('name', newCategory);
        if (categoryImage) {
          formData.append('image', categoryImage);
        }
  
        const response = await fetch(SummaryApi.createCateogory.url, {
          method: SummaryApi.createCateogory.method,
          credentials: 'include',
          body: formData,
        });
  
        const responseData = await response.json();
  
        if (responseData.success) {
          setCategories(prev => [...prev, { value: newCategory, label: newCategory }]);
          setNewCategory("");
          setCategoryImage(null);
          toast.success("New category added successfully!");
          setIsModalOpen(false);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        toast.error("Error adding category.");
      }
    }
  };
  

  const handleEditCategory = async () => {
    try {
      const formData = new FormData();
      if(editCategoryName){
        formData.append('name', editCategoryName);

      }
      if (editCategoryImage) {
        formData.append('image', editCategoryImage);
      } else {
        // If no new image is uploaded, keep the existing image.
        const existingImage = categories.find(cat => cat._id === editCategoryId).image;
        formData.append('image', existingImage); // Ensure this is handled by your server.
      }
  
      const response = await fetch(`${SummaryApi.editCategory.url}/${editCategoryId}`, {
        method: SummaryApi.editCategory.method,
        credentials: 'include',
        body: formData,
      });
  
      const responseData = await response.json();
      if (responseData.success) {
        setCategories(prev => 
          prev.map(cat => 
            cat._id === editCategoryId ? { ...cat, value: editCategoryName, image: responseData.image } : cat
          )
        );
        setEditCategoryName("");
        setEditCategoryImage(null);
        toast.success("Category updated successfully!");
        setIsEditModalOpen(false);
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error("Error editing category.");
    }
  };
  

  return (

    <>
   <div className="flex justify-center items-center text-center  md:mt-[10%]">
  <p className="text-lg md:text-xl font-semibold text-gray-700">
    Manage your categories efficiently: add, edit, or delete with ease.
  </p>
</div>


    <div className='p-4 '>
      <button
        className='p-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition-all duration-300 mb-4'
        onClick={() => setIsModalOpen(true)}
      >
        Add Category
      </button>

      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
        {categories?.map(category => (
          <div key={category._id} className='bg-white p-4 rounded border shadow-sm hover:shadow-md transition-shadow duration-300'>
            <div className='flex justify-between items-center'>
              <h2 className='text-lg font-semibold'>{category.value}</h2>
               
              <div className='flex gap-3'>
                <MdEdit
                  className='text-blue-600 cursor-pointer hover:text-blue-700'
                  onClick={() => {
                    setEditCategoryId(category?._id);
                    setEditCategoryName(category?.value);
                    setEditCategoryImage(category?.image);
                    setIsEditModalOpen(true);
                  }}
                />
                <MdDelete
                  className='text-red-600 cursor-pointer hover:text-red-700'
                  onClick={() => handleDeleteCategory(category?._id)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for adding category */}
      {isModalOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white p-6 rounded shadow-lg w-full max-w-md'>
            <h2 className='text-lg font-semibold mb-4'>Add New Category</h2>
            <input
              type='text'
              placeholder='Enter category name'
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              className='p-2 bg-slate-100 border rounded w-full mb-4'
            />

            <label htmlFor='uploadCategoryImageInput'>
              <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
                <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                  <span className='text-4xl'><FaCloudUploadAlt /></span>
                  <p className='text-sm'>Upload Category Image</p>
                  <input type='file' id='uploadCategoryImageInput' className='hidden' onChange={handleCategoryImageUpload} />
                </div>
              </div>
            </label>

            {categoryImage && (
  <div className='mt-2'>
    <img 
      src={URL.createObjectURL(categoryImage)} 
      alt='Category Preview' 
      className='w-32 h-32 object-cover' 
    />
  </div>
)}

            <div className='flex justify-between mt-4'>
              <button
                className='p-2 bg-green-500 text-white rounded hover:bg-green-600 transition-all duration-300'
                onClick={handleAddCategory}
              >
                Add Category
              </button>
              <button
                className='p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-all duration-300'
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for editing category */}
   
{isEditModalOpen && (
  <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
    <div className='bg-white p-6 rounded shadow-lg w-full max-w-md'>
      <h2 className='text-lg font-semibold mb-4'>Edit Category</h2>
      <input
        type='text'
        placeholder='Edit category name'
        value={editCategoryName}
        onChange={(e) => setEditCategoryName(e.target.value)}
        className='p-2 bg-slate-100 border rounded w-full mb-4'
      />

      <label htmlFor='uploadEditCategoryImageInput'>
        <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
          <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
            <span className='text-4xl'><FaCloudUploadAlt /></span>
            <p className='text-sm'>Upload New Category Image</p>
            <input type='file' id='uploadEditCategoryImageInput' className='hidden' onChange={handleEditCategoryImageUpload} />
          </div>
        </div>
      </label>

      {editCategoryImage ? (
  <div className='mt-2'>
    <img src={` https://builder.biztoindia.in/${editCategoryImage}`} alt='Category' className='w-32 h-32 object-cover' />
  </div>
) : (
  <div className='mt-2'>
    <img src={` https://builder.biztoindia.in/${categories?.find(cat => cat._id === editCategoryId)?.image}`} alt='Category' className='w-32 h-32 object-cover' />
  </div>
)}


      <div className='flex justify-between mt-4'>
        <button
          className='p-2 bg-green-500 text-white rounded hover:bg-green-600 transition-all duration-300'
          onClick={handleEditCategory}
        >
          Update Category
        </button>
        <button
          className='p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-all duration-300'
          onClick={() => setIsEditModalOpen(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}
    </div>
    </>
   
  );
};

export default AllCategoryUpdate;
