import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaRegCircleUser, FaCog, FaKey, FaEye, FaEyeSlash } from "react-icons/fa"; // Import FaEye and FaEyeSlash
import { Link } from "react-router-dom";
import Logo from "../assest/bizto.jpeg";
import { toast } from "react-toastify";

const AdminHeader = () => {
  const user = useSelector((state) => state?.user?.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirming new password
  const [showOldPassword, setShowOldPassword] = useState(false); // Toggle for old password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // Toggle for new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password visibility

  // Toggle Modals
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const togglePasswordModal = () => setIsPasswordModalOpen(!isPasswordModalOpen);

  // Handle Password Change Submission
  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match!");
      return;
    }

    try {
      const response = await fetch("https://builder.biztoindia.in/api/change-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
          userId: user?._id,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Password change failed.");

      toast.success(data.message); // Use react-toastify for better UX if needed
      togglePasswordModal(); // Close modal on success
      // Reset fields
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      {/* Header */}
      <header className="fixed top-0 left-0 w-full flex items-center justify-between p-1 bg-gray-50 shadow-lg z-50">
        <div className="flex items-center space-x-4">
          <Link to="/admin/dashboard" className="hover:text-blue-600 transition duration-200">
            <img src={Logo} alt="Logo" className="h-10 md:h-12" />
          </Link>
          <div className="text-lg md:text-2xl font-bold text-gray-800">
            <Link to="/admin" className="hover:text-blue-600 transition duration-200">
              Admin Panel
            </Link>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <p className="text-sm md:text-lg font-semibold text-gray-700 capitalize">
            👋 Welcome, <span className="text-blue-500">{user?.name || "Guest"}</span>
          </p>

          {/* Settings Button */}
          <button
            className="flex items-center p-1 text-sm font-medium bg-blue-700 text-white rounded-full hover:bg-blue-600 transition-all"
            onClick={toggleModal}
          >
            <FaCog className="h-5 w-5" />
          </button>
        </div>
      </header>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">{user?.role} Details</h2>
            <p className="mb-2"><strong>Name:</strong> {user?.name || "Not Provided"}</p>
            <p className="mb-2"><strong>Email:</strong> {user?.email || "Not Provided"}</p>
            <p className="mb-4"><strong>Phone:</strong> {user?.phoneNumber || "Not Added"}</p>
            
            {/* Change Password Button */}
            <div className="flex justify-center mb-4">
              <button
                className="flex items-center px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-lg hover:bg-green-600 transition-all"
                onClick={togglePasswordModal}
              >
                <FaKey className="mr-2" />
                Change Password
              </button>
            </div>

            {/* Close Button */}
            <div className="flex justify-center">
              <button
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded transition-all hover:bg-red-600"
                onClick={toggleModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Password Change Modal */}
      {isPasswordModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Change Password</h2>

            {/* Old Password */}
            <div className="relative mb-4">
              <input
                type={showOldPassword ? "text" : "password"}
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="w-full p-2 border rounded"
              />
              <button
                type="button"
                className="absolute right-2 top-2"
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                {showOldPassword ? <FaEye className="h-5 w-5" /> : <FaEyeSlash className="h-5 w-5" />}
              </button>
            </div>

            {/* New Password */}
            <div className="relative mb-4">
              <input
                type={showNewPassword ? "text" : "password"}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 border rounded"
              />
              <button
                type="button"
                className="absolute right-2 top-2"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaEye className="h-5 w-5" /> : <FaEyeSlash className="h-5 w-5" />}
              </button>
            </div>

            {/* Confirm New Password */}
            <div className="relative mb-4">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Re-enter New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 border rounded"
              />
              <button
                type="button"
                className="absolute right-2 top-2"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEye className="h-5 w-5" /> : <FaEyeSlash className="h-5 w-5" />}
              </button>
            </div>
            
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={handlePasswordChange}
            >
              Submit
            </button>
            <button
              className="ml-4 bg-red-500 text-white px-4 py-2 rounded"
              onClick={togglePasswordModal}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminHeader;
