import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const AboutUs = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector(state => state?.user?.user);
  const [showFullMission, setShowFullMission] = useState(false);
  const [showFullWhyChooseUs, setShowFullWhyChooseUs] = useState(false);
  const [showFullAboutUs, setShowFullAboutUs] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  const fallbackData = {
    companyName: 'Empowering Your Online Shopping Experience',
    aboutUs: 'At our Ecommerce Store, we are committed to providing the best online shopping experience. We value our customers and strive to offer exceptional service.',
    ourMission: 'Our mission is to ensure that every customer finds what they need at an unbeatable value.',
    whyChooseUs: 'From high-quality products to fast delivery and easy returns, our platform is designed with the customer in mind.'
  };

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(` https://builder.biztoindia.in/api/get-body-color/${user?._id}`);
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [user?._id]);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${user?._id}`);
        if (response.ok) {
          const data = await response.json();
          setAboutData(data);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutData();
  }, [user?._id]);

  if (loading) {
    return <p className="text-center text-lg font-semibold">Loading...</p>;
  }

  return (
    <section
      style={{
        background: `${backgroundColor} `,
        padding: '4rem 1rem'
      }}
    >
      <Helmet>
        <title>{aboutData?.companyName + ' About Us'}</title>
      </Helmet>

      <div className="max-w-7xl mx-auto text-center">
        <p className="mt-2 text-4xl leading-10 font-extrabold  sm:text-5xl" 
        style={{
           color: `${primaryColor} `,
           
        }}
        >
          {aboutData?.companyName || fallbackData.companyName}
        </p>
        
        <div className="mt-6 w-full text-lg   mx-auto" 
        style={{
          color: `${secondaryColor} `,
          
       }}
        >
          {showFullAboutUs
            ? aboutData?.aboutUs || fallbackData.aboutUs
            : `${(aboutData?.aboutUs || fallbackData.aboutUs).substring(0, 200)}...`}
        </div>
        <button
          onClick={() => setShowFullAboutUs(!showFullAboutUs)}
          className="text-red-600 flex items-center justify-center font-semibold hover:text-blue-800 transition-all duration-300 mt-4"
          style={{
            color: `${primaryColor} `,
            
         }}
       >
          {showFullAboutUs ? (
            <>
              <FaChevronUp className="mr-2" /> Show Less
            </>
          ) : (
            <>
              <FaChevronDown className="mr-2" /> Read More
            </>
          )}
        </button>
      </div>

      <div className="mt-12 max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="space-y-6 p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
          <h3 className="text-3xl font-semibold " style={{
            color: `${primaryColor} `,
            
         }}>Our Mission</h3>
          <p className="text-lg " style={{
            color: `${ secondaryColor} `,
            
         }}>
            {showFullMission
              ? aboutData?.ourMission || fallbackData.ourMission
              : `${(aboutData?.ourMission || fallbackData.ourMission).substring(0, 200)}...`}
          </p>
          <button
            onClick={() => setShowFullMission(!showFullMission)}
            className=" flex items-center font-semibold hover:text-blue-800 transition-all duration-300"
            style={{
              color: `${primaryColor} `,
              
           }}
          >
            {showFullMission ? (
              <>
                <FaChevronUp className="mr-2" /> Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" /> Read More
              </>
            )}
          </button>
        </div>

        <div className="space-y-6 p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
          <h3 className="text-3xl font-semibold " style={{
            color: `${primaryColor} `,
            
         }}>Why Choose Us?</h3>
          <p className="text-lg " style={{
            color: `${secondaryColor} `,
            
         }}>
            {showFullWhyChooseUs
              ? aboutData?.whyChooseUs || fallbackData.whyChooseUs
              : `${(aboutData?.whyChooseUs || fallbackData.whyChooseUs).substring(0, 200)}...`}
          </p>
          <button
            onClick={() => setShowFullWhyChooseUs(!showFullWhyChooseUs)}
            className=" flex items-center font-semibold hover:text-blue-800 transition-all duration-300"
            style={{
              color: `${primaryColor} `,
              
           }}
          >
            {showFullWhyChooseUs ? (
              <>
                <FaChevronUp className="mr-2" /> Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" /> Read More
              </>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
