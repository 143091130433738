import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const WhatsAppIntegration = () => {
  const [token, setToken] = useState('');
  const [phone, setPhone] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateLanguage, setTemplateLanguage] = useState('');
  const [api, setApi] = useState('');  

  const user = useSelector(state => state?.user?.user);

  useEffect(() => {
    const fetchWhatsAppConfig = async () => {
      if (!user?._id) return; // Exit if user ID is not available

      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-details-of-whatsapp-api/${user._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch WhatsApp configuration.');
        }

        const data = await response.json();

        if (data?.success) {
          setToken(data.data.token || '');
          setPhone(data.data.phone || '');
          setTemplateName(data.data.template_name || '');
          setTemplateLanguage(data.data.template_language || '');
          setApi(data.data.api || ''); // Set api field from fetched data
        } else {
          toast.error('Failed to load WhatsApp configuration.');
        }
      } catch (error) {
        toast.error('Error fetching WhatsApp configuration.');
      }
    };

    fetchWhatsAppConfig();
  }, [user?._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://builder.biztoindia.in/api/whatsapp-api-integreation', {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          phone,
          template_name: templateName,
          template_language: templateLanguage,
          api // Include the api field
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Failed to send WhatsApp message.'); // Better error message
      }

      toast.success("API Integrated Successfully");
    } catch (error) {
      toast.error(error.message || 'Failed to Integrate WhatsApp API'); // Show specific error
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow-lg rounded-lg mt-[40%] md:mt-[10%] ">
      <h1 className="text-2xl font-bold mb-6 text-gray-800">WhatsApp API Integration</h1>
      <Toaster />
      <form onSubmit={handleSubmit} className="space-y-4">
        
        {/* Token input */}
        <div>
          <label htmlFor="token" className="block text-sm font-medium text-gray-700">
            Token
          </label>
          <input
            type="text"
            id="token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* Phone input */}
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder='+1234567890'
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* Template Name input */}
        <div>
          <label htmlFor="templateName" className="block text-sm font-medium text-gray-700">
            Template Name
          </label>
          <input
            type="text"
            id="templateName"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder='Template Name'
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* Template Language input */}
        <div>
          <label htmlFor="templateLanguage" className="block text-sm font-medium text-gray-700">
            Template Language
          </label>
          <input
            type="text"
            id="templateLanguage"
            value={templateLanguage}
            onChange={(e) => setTemplateLanguage(e.target.value)}
            placeholder='en_US'
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* API input */}
        <div>
          <label htmlFor="api" className="block text-sm font-medium text-gray-700">
            API
          </label>
          <input
            type="text"
            id="api"
            value={api}
            onChange={(e) => setApi(e.target.value)}
            placeholder='https://wa.biztoindia.com'
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            required
          />
        </div>

        {/* Submit button */}
        <button
          type="submit"
          className="w-full py-2 px-4 bg-green-600 text-white font-semibold rounded-md shadow-sm hover:bg-green-700 focus:outline-none hover:text-black focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          Integrate WhatsApp API
        </button>
      </form>
    </div>
  );
};

export default WhatsAppIntegration;
