import React, { useEffect, useState } from "react";
import { toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Toastify styles

const SuperAdminEnquiry = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch("https://builder.biztoindia.in/api/get-bizto-enquiry");
        if (!response.ok) {
          throw new Error("Failed to fetch enquiries.");
        }
        const data = await response.json();
        console.log("faua",data);
        setEnquiries(data.data); // Store enquiries data
        toast.success("Enquiries fetched successfully!"); // Success toast
      } catch (err) {
        console.error("Error fetching enquiries:", err);
        setError("Failed to fetch enquiries.");
        toast.error("Failed to fetch enquiries!"); // Error toast
      } finally {
        setLoading(false);
      }
    };

    fetchEnquiries();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="spinner-border animate-spin w-10 h-10 border-4 rounded-full"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-600 text-center">{error}</div>;
  }

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold text-center mb-8">All Enquiries</h1>

      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-4">Name</th>
              <th className="border border-gray-300 p-4">Email</th>
              <th className="border border-gray-300 p-4">Phone</th>
              <th className="border border-gray-300 p-4">Subject</th>
              <th className="border border-gray-300 p-4">Message</th>
              <th className="border border-gray-300 p-4">Date</th>
            </tr>
          </thead>
          <tbody>
            {enquiries.map((enquiry) => (
              <tr key={enquiry._id} className="hover:bg-gray-100">
                <td className="border border-gray-300 p-4">{enquiry.name}</td>
                <td className="border border-gray-300 p-4">{enquiry.email}</td>
                <td className="border border-gray-300 p-4">{enquiry.phone}</td>
                <td className="border border-gray-300 p-4">{enquiry.subject}</td>
                <td className="border border-gray-300 p-4">{enquiry.message}</td>
                <td className="border border-gray-300 p-4">
                <td className="border border-gray-300 p-4">
  {new Date(enquiry.createdAt).toLocaleString('en-IN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Optional: Set to false for 24-hour format
  })}
</td>

</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuperAdminEnquiry;
