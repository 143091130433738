import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const UpdateAbout = () => {
  const [aboutUs, setAboutUs] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [instagramId, setInstagramId] = useState('');
  const [facebookId, setFacebookId] = useState('');
  const [twitterId, setTwitterId] = useState('');
  const [youtubeId, setYoutubeId] = useState('');
  const [linkedinId, setLinkedinId] = useState('');
  const [whyChooseUs, setWhyChooseUs] = useState('');
  const [ourMission, setOurMission] = useState('');
  const [companyName, setCompanyName] = useState('');
  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${user?._id}`);
        if (response.ok) {
          const data = await response.json();
          setAboutUs(data.aboutUs);
          setContactNumber(data.contactNumber);
          setEmailId(data.emailId);
          setInstagramId(data.instagramId);
          setFacebookId(data.facebookId);
          setTwitterId(data.twitterId);
          setYoutubeId(data.youtubeId);
          setLinkedinId(data.linkedinId);
          setWhyChooseUs(data.whyChooseUs);
          setOurMission(data.ourMission);
          setCompanyName(data.companyName);
        } else {
          toast.error('Failed to fetch existing data.');
        }
      } catch (error) {
        toast.error('An error occurred while fetching existing data.');
        console.error(error);
      }
    };

    fetchAboutData();
  }, [user?._id]);

  const resetForm = () => {
    setAboutUs('');
    setContactNumber('');
    setEmailId('');
    setInstagramId('');
    setFacebookId('');
    setTwitterId('');
    setYoutubeId('');
    setLinkedinId('');
    setWhyChooseUs('');
    setOurMission('');
    setCompanyName('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://builder.biztoindia.in/api/updateabout', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          aboutUs,
          contactNumber,
          emailId,
          instagramId,
          facebookId,
          twitterId,
          youtubeId,
          linkedinId,
          whyChooseUs,
          ourMission,
          companyName,
        }),
      });

      if (response.ok) {
        toast.success('About Us updated successfully!');
        resetForm();
      } else {
        toast.error('Failed to update About Us.');
      }
    } catch (error) {
      toast.error('An error occurred while updating.');
      console.error(error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-100 to-gray-300 p-6">
      <Toaster position="top-right" reverseOrder={false} />
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8 transition-transform duration-300 hover:shadow-xl transform hover:scale-105"
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-blue-600">Update About Us</h2>
        

        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="aboutUs"
          >
            About Us
          </label>
          <textarea
            id="aboutUs"
            value={aboutUs}
            onChange={(e) => setAboutUs(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            rows="4"
            placeholder="Share your company’s story, vision, and core values..."
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="contactNumber"
          >
            Contact Number
          </label>
          <input
            id="contactNumber"
            type="text"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Enter your contact number"
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="emailId"
          >
            Email Address
          </label>
          <input
            id="emailId"
            type="email"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Enter your email address"
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="instagramId"
          >
            Instagram ID
          </label>
          <input
            id="instagramId"
            type="text"
            value={instagramId}
            onChange={(e) => setInstagramId(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Enter your Instagram handle"
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="facebookId"
          >
            Facebook ID
          </label>
          <input
            id="facebookId"
            type="text"
            value={facebookId}
            onChange={(e) => setFacebookId(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Enter your Facebook handle"
            required
          />
        </div>
        <InputField
          id="twitterId"
          label="Twitter ID"
          value={twitterId}
          onChange={(e) => setTwitterId(e.target.value)}
          placeholder="Enter your Twitter handle"
        />

        <InputField
          id="youtubeId"
          label="YouTube ID"
          value={youtubeId}
          onChange={(e) => setYoutubeId(e.target.value)}
          placeholder="Enter your YouTube channel"
        />

        <InputField
          id="linkedinId"
          label="LinkedIn ID"
          value={linkedinId}
          onChange={(e) => setLinkedinId(e.target.value)}
          placeholder="Enter your LinkedIn profile"
        />
        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="whyChooseUs"
          >
            Why Choose Us?
          </label>
          <textarea
            id="whyChooseUs"
            value={whyChooseUs}
            onChange={(e) => setWhyChooseUs(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            rows="3"
            placeholder="Explain what makes your company stand out..."
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="ourMission"
          >
            Our Mission
          </label>
          <textarea
            id="ourMission"
            value={ourMission}
            onChange={(e) => setOurMission(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            rows="3"
            placeholder="Describe your mission and core objectives"
            required
          />
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
            htmlFor="companyName"
          >
            Company Name
          </label>
          <input
            id="companyName"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Enter your company name"
            required
          />
        </div>


        {/* Other Fields */}
     

        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-full transition duration-200 focus:outline-none focus:shadow-outline"
        >
          Update
        </button>
      </form>
    </div>
  );
};

const InputField = ({ id, label, value, onChange, placeholder }) => (
  <div className="mb-6">
    <label
      className="block text-gray-700 text-lg font-bold mb-2 transition-colors duration-300 hover:text-blue-600"
      htmlFor={id}
    >
      {label}
    </label>
    <input
      id={id}
      type="text"
      value={value}
      onChange={onChange}
      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
      placeholder={placeholder}
      required
    />
  </div>
);

export default UpdateAbout;
