import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const ContactIntegration = () => {
  const [contactData, setContactData] = useState({
    phone: '',
    email: '',
    officeAddress: {
      address: '',
      city: '',
      state: '',
      pinCode: '',
      sector: '',
      gstMsme: '',
    },
    registeredAddress: {
      address: '',
      city: '',
      state: '',
      pinCode: '',
      sector: '',
      gstMsme: '',
    },
    googleMapIframe: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector(state => state?.user?.user);

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-contact/${user?._id}`);
        const data = await response.json();
        if (!response.ok) {
          toast.error("Error fetching contact details.");
          return;
        }
        setContactData(data?.data || {});
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      }
    };

    if (user?._id) {
      fetchContactDetails();
    }
  }, [user?._id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');

    if (field) {
      setContactData({
        ...contactData,
        [section]: {
          ...contactData[section],
          [field]: value
        }
      });
    } else {
      setContactData({ ...contactData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch('https://builder.biztoindia.in/api/contact-det', {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });

      if (!response.ok) {
        toast.error('Error updating contact details.');
        return;
      }

      toast.success('Contact details updated successfully!');
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Update Contact Information</title>
      </Helmet>

      <h1 className="text-4xl font-extrabold text-center mb-8 text-gray-900">Update Your Contact Information</h1>

      <p className="text-center text-gray-600 mb-10">Keep your business details up to date. Ensure the addresses and contact numbers are accurate for clients to reach you effectively.</p>

      <form className="bg-white p-8 rounded-lg shadow-lg max-w-4xl mx-auto transition-transform duration-300 ease-in-out transform hover:scale-105" onSubmit={handleSubmit}>
        <div className="grid gap-8 mb-6 md:grid-cols-2">
          {/* Phone and Email */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Phone</label>
            <input
              type="text"
              name="phone"
              value={contactData?.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={contactData?.email}
              onChange={handleChange}
              placeholder="Enter your email address"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Office Address */}
          <div className="col-span-2">
            <h2 className="text-2xl font-bold text-center mb-4 text-gray-900">Office Address</h2>
            {['address', 'sector', 'city', 'state', 'pinCode', 'gstMsme'].map(field => (
              <div key={field} className="mb-4">
                <label className={`block text-gray-700 font-semibold mb-2 capitalize`}>
                  {field.split(/(?=[A-Z])/).join(' ')}
                </label>
                <input
                  type="text"
                  name={`officeAddress.${field}`}
                  value={contactData?.officeAddress?.[field]}
                  onChange={handleChange}
                  placeholder={`Enter office ${field.split(/(?=[A-Z])/).join(' ').toLowerCase()}`}
                  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                />
              </div>
            ))}
          </div>

          {/* Registered Address */}
          <div className="col-span-2">
            <h2 className="text-2xl font-bold text-center mb-4 text-gray-900">Registered Address</h2>
            {['address', 'sector', 'city', 'state', 'pinCode', 'gstMsme'].map(field => (
              <div key={field} className="mb-4">
                <label className={`block text-gray-700 font-semibold mb-2 capitalize`}>
                  {field.split(/(?=[A-Z])/).join(' ')}
                </label>
                <input
                  type="text"
                  name={`registeredAddress.${field}`}
                  value={contactData?.registeredAddress?.[field]}
                  onChange={handleChange}
                  placeholder={`Enter registered ${field.split(/(?=[A-Z])/).join(' ').toLowerCase()}`}
                  className="w-full p-3 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                />
              </div>
            ))}
          </div>

          {/* Google Map Iframe */}
          <div className="col-span-2">
            <label className="block text-gray-700 font-semibold mb-2">Google Map Iframe</label>
            <textarea
              name="googleMapIframe"
              value={contactData?.googleMapIframe}
              onChange={handleChange}
              placeholder="Paste Google Map iframe link"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
              rows="3"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className={`w-full md:w-auto bg-blue-600 text-white p-3 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Updating...' : 'Update Contact Info'}
          </button>
        </div>
      </form>

      <Toaster />
    </div>
  );
};

export default ContactIntegration;
