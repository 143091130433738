import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const FaqIntegration = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentFaqId, setCurrentFaqId] = useState(null); // Track which FAQ is being edited
  const user = useSelector((state) => state?.user?.user);

  // Fetch all FAQs on component mount
  const fetchFaqs = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/get-faq/${user?._id}`);

      if (!response.ok) throw new Error('Error fetching FAQs');
      const data = await response.json();
      setFaqs(data);
    } catch (error) {
      // toast.error('Error fetching FAQs.');
    }
  };

  // Submit a new FAQ or update an existing one
  const handleSubmit = async (e) => {
    e.preventDefault();
    const faqData = { question, answer };

    try {
      let response;
      if (editMode) {
        // Edit existing FAQ
        response = await fetch(`https://builder.biztoindia.in/api/faqs/${currentFaqId}`, {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(faqData),
        });
      } else {
        // Create new FAQ
        response = await fetch('https://builder.biztoindia.in/api/post-faq', {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(faqData),
        });
      }

      const result = await response.json();

      if (response.ok) {
        if (editMode) {
          setFaqs((prev) =>
            prev.map((faq) => (faq._id === currentFaqId ? result : faq))
          );
          toast.success('FAQ updated successfully');
        } else {
          setFaqs((prev) => [result, ...prev]);
          toast.success('FAQ successfully submitted');
        }
        resetForm();
      } else {
        toast.error(`Error: ${result.message}`);
      }
    } catch (error) {
      toast.error('Error submitting FAQ');
    }
  };

  // Reset form state
  const resetForm = () => {
    setQuestion('');
    setAnswer('');
    setEditMode(false);
    setCurrentFaqId(null);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/faqs/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Error deleting FAQ');

      setFaqs((prev) => prev.filter((faq) => faq._id !== id));
      toast.success('FAQ deleted successfully');
    } catch (error) {
      toast.error('Error deleting FAQ.');
    }
  };

  // Handle edit
  const handleEdit = (faq) => {
    setEditMode(true);
    setCurrentFaqId(faq._id);
    setQuestion(faq.question);
    setAnswer(faq.answer);
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-2xl font-semibold text-center mb-6">Manage FAQs</h1>

        {/* Submit New FAQ or Edit Existing FAQ */}
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Question:
            </label>
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Answer:
            </label>
            <textarea
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition"
          >
            {editMode ? 'Update FAQ' : 'Submit'}
          </button>
        </form>

        {/* List of FAQs */}
        <div>
          <h2 className="text-xl font-semibold mb-4">All FAQs</h2>
          {faqs?.map((faq) => (
            <div
              key={faq._id}
              className="flex justify-between items-center mb-4 p-4 border rounded-lg"
            >
              <div>
                <h3 className="font-semibold">{faq.question}</h3>
                <p className="text-gray-700">{faq.answer}</p>
              </div>
              <div>
                <button
                  onClick={() => handleEdit(faq)}
                  className="text-blue-500 hover:text-blue-700 mr-4"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(faq._id)}
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default FaqIntegration;
