import { 
  FaChartLine, FaEnvelope, FaList, FaBoxes, FaPhoneAlt, FaGlobe 
} from 'react-icons/fa'; // For icons
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
 
import { useSelector } from 'react-redux';
import SummaryApi from '../common';
 

const Dashboard = () => {
  const [categories, setCategories] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [enquiries, setEnquiries] = useState([]);
  const [recentEnquiries, setrecentEnquiries] = useState([]);
  const user = useSelector((state) => state?.user?.user);

  // Fetch Categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${SummaryApi.allcategory.url}/${user?._id}`,
          { method: SummaryApi.allcategory.method, credentials: 'include', headers: { "Content-Type": "application/json" } }
        );

        const data = await response.json();
        if (data.success) setCategories(data.categories);
        // else toast.error(data.message);
      } catch (error) {
        // console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, [user?._id]);

  // Fetch Enquiries
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-enquiry/${user?._id}`);
        const data = await response.json();

        const last24Hours = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const recentEnquiries = data?.data?.filter((enquiry) => {
          const enquiryDate = new Date(enquiry.createdAt);
          return enquiryDate >= last24Hours;
        });

        setrecentEnquiries(recentEnquiries);
        setEnquiries(data.data);
      } catch (error) {
        // console.error("Error fetching enquiries:", error);
      }
    };
    fetchEnquiries();
  }, [user?._id]);

  // Fetch Products
  useEffect(() => {
    const fetchAllProduct = async () => {
      try {
        const response = await fetch(`${SummaryApi.allProduct.url}/${user._id}`);
        const data = await response.json();
        setAllProduct(data?.data || []);
      } catch (error) {
        // console.error("Failed to fetch products:", error);
      }
    };
    fetchAllProduct();
  }, []);

  return (
    <div className="p-4 md:p-8 bg-white min-h-screen">
      <Helmet>
        <title>Admin Dashboard | BizToIndia</title>
      </Helmet>

      {/* Welcome Section */}
      <div className="mb-8">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 text-center md:text-left">
          Welcome to BizToIndia Admin Dashboard
        </h1>
        <p className="text-gray-600 mt-2 text-center md:text-left">
          Manage all aspects of the platform from here.
        </p>
      </div>

      {/* Overview Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-yellow-400 text-white rounded-lg p-6 flex items-center">
          <FaChartLine className="w-8 h-8 mr-4" />
          <div>
            <h2 className="text-lg font-bold">Total Enquiries</h2>
            <p className="text-xl">{enquiries.length || '0'}</p>
          </div>
        </div>

        <div className="bg-blue-500 text-white rounded-lg p-6 flex items-center">
          <FaList className="w-8 h-8 mr-4" />
          <div>
            <h2 className="text-lg font-bold">Categories</h2>
            <p className="text-xl">{categories.length || '0'}</p>
          </div>
        </div>

        <div className="bg-green-500 text-white rounded-lg p-6 flex items-center">
          <FaBoxes className="w-8 h-8 mr-4" />
          <div>
            <h2 className="text-lg font-bold">Total Products</h2>
            <p className="text-xl">{allProduct.length || '0'}</p>
          </div>
        </div>
      </div>

      {/* Recent Enquiries */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-gray-800">Last 24 Hours Enquiries</h2>
        <div className="mt-4 bg-gray-100 p-4 rounded-lg">
          <p className="text-lg">{recentEnquiries.length || '0'} enquiries received</p>
        </div>
      </div>

      {/* Contact Information */}
      <div className="mt-12 bg-gray-100 p-6 rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800">Need Help?</h2>

        <div className="mt-4 flex items-center">
          <FaGlobe className="w-6 h-6 text-gray-700 mr-2" />
          <a
            href="https://www.biztoindia.com"
            className="text-blue-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.biztoindia.com
          </a>
        </div>

        <div className="mt-6 flex items-center">
          <FaPhoneAlt className="w-6 h-6 text-gray-700 mr-2" />
          <p className="text-gray-700">+91 9910443978</p>
        </div>

        <div className="mt-2 flex items-center">
          <FaEnvelope className="w-6 h-6 text-gray-700 mr-2" />
          <p className="text-gray-700">info@biztoindia.com</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
