import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';
import { AiOutlineAppstore,AiOutlineClose } from 'react-icons/ai';
 
 
import loginIcons from '../assest/forgot.jpg'; // Update the path if needed
import bizto from '../assest/bizto.jpeg';
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
 
 

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://builder.biztoindia.in/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success('Password reset link sent to your email!');
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      toast.error(err.message || 'Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit1= async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://builder.biztoindia.in/api/enquiry-builder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
  console.log("Enquiry data",data);
      if (response.ok) {
        toast.success("Enquiry submitted successfully!");
        setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
        setIsModalOpen(false); // Close modal on success
      } else {
        toast.error(data.message || "Failed to submit enquiry");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-purple-700 to-indigo-900">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 flex justify-between items-center px-8 py-4 bg-white shadow-md">
                <div className="flex flex-col md:flex-row items-center md:space-x-4 text-center md:text-left">
                    <div className="w-16 h-16 overflow-hidden">
                        <img src={bizto} alt="BizTo India Logo" className="w-full h-full object-contain" />
                    </div>
                    <h1 className="hidden md:block text-3xl font-bold text-yellow-500">Bizto India</h1>
                </div>

                <div className="flex items-center space-x-4 sm:space-x-6">
                    <a href="tel:+9910443978">
                    <button className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <IoCall className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Call Us</span>
                    </button>
                    </a>
                    <a href="mailto:info@biztoindia.com" className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <MdEmail className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Email Us</span>
                    </a>
                </div>
            </header>

      {/* Main Content */}
      <div className="flex flex-1 mt-16 mb-16 md:flex-row flex-col  ">
        {/* Left Half with Image and Text */}
        <div className="w-full md:w-1/2 p-10 md:p-16 flex flex-col justify-center space-y-6 text-white">
          <h2 className="text-4xl md:text-5xl font-extrabold">Create Your Own Online Presence</h2>
          <p className="text-lg leading-relaxed">
            Join us today and explore endless possibilities to grow your business with our comprehensive online solutions.
          </p>
          <div className="space-x-4">
                        <button
                            onClick={() => setIsModalOpen(true)} // Open Modal
                            className="bg-yellow-500 px-6 py-2 rounded-full hover:bg-yellow-600 transition-all duration-300 shadow-lg"
                        >
                            Enquire Now
                        </button>
                    </div>
        </div>

        {/* Right Half with Form */}
        <div className="flex-1 flex items-center justify-center p-4">
          <Toaster position="top-center" reverseOrder={false} />
          <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-2xl rounded-lg bg-opacity-90 backdrop-blur-sm">
            <h2 className="text-4xl font-bold text-center text-gray-800">Forgot Password</h2>
            <p className="text-sm text-gray-500 text-center">
              Enter your email to receive a password reset link.
            </p>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            />
            <button
              onClick={handleForgotPassword}
              disabled={loading}
              className={`w-full px-4 py-2 text-white rounded-md transition duration-200 ${
                loading ? 'bg-yellow-500 cursor-not-allowed' : 'bg-yellow-500 hover:bg-blue-700'
              } focus:outline-none focus:ring-2 focus:ring-blue-400`}
            >
              {loading ? 'Sending...' : 'Send Reset Link'}
            </button>
            <p className="text-center text-sm text-gray-400">
              Back to login page?{' '}
              <Link to="/login" className="text-blue-600 hover:underline">
                Login here
              </Link>
            </p>
          </div>
        </div>
      </div>
      {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                  <h2 className="text-xl font-bold mb-4 text-yellow-500">Enquire Now</h2>
                  <form className="flex flex-col gap-4 text-gray-800" onSubmit={handleSubmit1}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Your Phone Number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      rows="4"
                      required
                    />
                    <button
                      type="submit"
                      className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition"
                    >
                      Submit
                    </button>
                  </form>
                  <button
                    onClick={() => setIsModalOpen(false)} // Close Modal
                    className="absolute top-2 right-2 text-red-500"
                  >
                    <AiOutlineClose className="text-xl" />
                  </button>
                </div>
              </div>
            )}
      {/* Footer */}
      <footer className="fixed bottom-0 left-0 right-0 py-4 bg-white shadow-md">
        <p className="text-center text-yellow-500 text-sm">&copy; 2024 BizToIndia. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ForgotPassword;
