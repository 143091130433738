import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';  // Importing toast from react-hot-toast
import { useSelector } from 'react-redux';

const HeaderUpdate = () => {
  const [headerTitle, setHeaderTitle] = useState('');  // State to store header title
  const [loading, setLoading] = useState(true);  // State to manage loading
  const user = useSelector(state => state?.user?.user);

  // Fetch the current header title when the component mounts
  useEffect(() => {
    const fetchHeaderTitle = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-header/${user?._id}`);
        const data = await response.json();  // Parse the response JSON

        if (response.ok) {
          setHeaderTitle(data?.headerTitle);  // Set the fetched header title
        } else {
          toast.error(data.message || 'Error fetching header title');  // Show error message if fetch fails
        }
      } catch (error) {
        toast.error('Error fetching header title');  // Handle fetch error
      } finally {
        setLoading(false);  // Set loading to false after fetch completes
      }
    };

    fetchHeaderTitle();  // Call the fetch function
  }, [user?._id]);

  const handleUpdate = async () => {
    const updateToast = toast.loading('Updating header...');  // Display loading notification

    try {
      const response = await fetch('https://builder.biztoindia.in/api/update-header', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ headerTitle }),  // Send updated headerTitle
        credentials: 'include',  // Include credentials (e.g., cookies) with the request
      });

      const data = await response.json();  // Parse response data

      if (response.ok) {
        toast.success('Header updated successfully!', { id: updateToast, duration: 3000 });  // Success notification
      } else {
        toast.error(data.message || 'Error updating header', { id: updateToast });  // Error handling
      }
    } catch (error) {
      toast.error('Error updating header', { id: updateToast });  // Catch error during fetch
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-4 text-blue-600">Update Your Header</h1>
      <p className="text-gray-700 mb-6 text-center">
        Customize the header title to reflect your brand or latest updates. 
        Please enter your desired title below:
      </p>

      {loading ? (  // Show loading text while fetching data
        <p className="text-gray-500">Loading...</p>
      ) : (
        <>
          <input
            type="text"
            value={headerTitle}
            onChange={(e) => setHeaderTitle(e.target.value)}
            placeholder="Enter new header title"
            className="border border-gray-300 rounded-md p-3 mb-4 w-full max-w-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleUpdate}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Update Header
          </button>
        </>
      )}
    </div>
  );
};

export default HeaderUpdate;
