import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FaPhoneAlt, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';
import { AiOutlineAppstore,AiOutlineClose } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css';
import bizto from '../assest/bizto.jpeg'; // Logo
import imageUrl from '../assest/forgot.jpg'; // Reset password image
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
 
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const navigate = useNavigate();
 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const handleResetPassword = async () => {
    try {
      const response = await fetch('https://builder.biztoindia.in/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
      });

      const data = await response.json();
    
      if (data.success) {
        toast.success('Password reset successful!');
        navigate('/login');
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      if(error.message===`jwt expired`){
        toast.error("Please Resend Verification Link Your Token Is Expired")
      } else {

        toast.error(error.message);
      }

    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit1= async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://builder.biztoindia.in/api/enquiry-builder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
  console.log("Enquiry data",data);
      if (response.ok) {
        toast.success("Enquiry submitted successfully!");
        setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
        setIsModalOpen(false); // Close modal on success
      } else {
        toast.error(data.message || "Failed to submit enquiry");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-purple-700 to-indigo-900">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 flex justify-between items-center px-8 py-4 bg-white shadow-md">
                <div className="flex flex-col md:flex-row items-center md:space-x-4 text-center md:text-left">
                    <div className="w-16 h-16 overflow-hidden">
                        <img src={bizto} alt="BizTo India Logo" className="w-full h-full object-contain" />
                    </div>
                    <h1 className="hidden md:block text-3xl font-bold text-yellow-500">Bizto India</h1>
                </div>

                <div className="flex items-center space-x-4 sm:space-x-6">
                    <a href="tel:+9910443978">
                    <button className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <IoCall className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Call Us</span>
                    </button>
                    </a>
                    <a href="mailto:info@biztoindia.com" className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-400 transition">
                        <MdEmail className="text-xl sm:text-2xl mr-2" />
                        <span className="text-sm sm:text-base">Email Us</span>
                    </a>
                </div>
            </header>
      {/* Main Content */}
      <div className="flex flex-1 mt-16 mb-16 md:flex-row flex-col">
      <div className="w-full md:w-1/2 p-10 md:p-16 flex flex-col justify-center space-y-6 text-white">
          <h2 className="text-4xl md:text-5xl font-extrabold">Create Your Own Online Presence</h2>
          <p className="text-lg leading-relaxed">
            Join us today and explore endless possibilities to grow your business with our comprehensive online solutions.
          </p>
          <div className="space-x-4">
                        <button
                            onClick={() => setIsModalOpen(true)} // Open Modal
                            className="bg-yellow-500 px-6 py-2 rounded-full hover:bg-yellow-600 transition-all duration-300 shadow-lg"
                        >
                            Enquire Now
                        </button>
                    </div>
        </div>
        <div className="flex items-center justify-center w-full md:w-1/2 p-8">
          <div className="w-full max-w-md space-y-6 bg-white shadow-2xl rounded-lg p-8">
            <h2 className="text-4xl font-bold text-center text-gray-800">Reset Password</h2>
            <p className="text-sm text-gray-500 text-center">Enter your new password below.</p>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleResetPassword}
              className="w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Reset Password
            </button>
            <p className="text-center text-sm text-gray-400">
              Remembered your password?{' '}
              <Link to="/login" className="text-blue-600 hover:underline">
                Login here
              </Link>
            </p>
          </div>
        </div>
      </div>
      {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                  <h2 className="text-xl font-bold mb-4 text-yellow-500">Enquire Now</h2>
                  <form className="flex flex-col gap-4 text-gray-800" onSubmit={handleSubmit1}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Your Phone Number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      required
                    />
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      className="p-2 border rounded"
                      rows="4"
                      required
                    />
                    <button
                      type="submit"
                      className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition"
                    >
                      Submit
                    </button>
                  </form>
                  <button
                    onClick={() => setIsModalOpen(false)} // Close Modal
                    className="absolute top-2 right-2 text-red-500"
                  >
                    <AiOutlineClose className="text-xl" />
                  </button>
                </div>
              </div>
            )}
      {/* Footer */}
      <footer className="bg-white bottom-0 left-0 right-0 py-4">
         
        <p className="text-center mt-2 text-yellow-500 text-sm">&copy; 2024 BizToIndia. All Rights Reserved.</p>
      </footer>

      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
