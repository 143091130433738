import React, { useEffect, useState } from 'react';
import UploadProduct from '../components/UploadProduct';
import SummaryApi from '../common';
import AdminProductCard from '../components/AdminProductCard';
import { useSelector } from 'react-redux';

const AllProducts = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const user = useSelector(state => state?.user?.user);

  // Fetch all products associated with the user
  const fetchAllProduct = async () => {
    try {
      const response = await fetch(`${SummaryApi.allProduct.url}/${user._id}`);
      const dataResponse = await response.json();
      setAllProduct(dataResponse?.data || []);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  return (
    <div className="p-4">
      <div className="bg-white py-3 px-5 flex justify-between items-center shadow-md rounded-lg">
        <h2 className="font-bold md:text-xl text-sm text-gray-800">All Products</h2>
        <button
          className="border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all md:py-2 md:px-4  md:text-xl text-sm rounded-full"
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload New Product
        </button>
      </div>

      <p className="mt-2 text-gray-600 text-center">
        Manage your products efficiently. Click on any product card for more details or to edit it.
      </p>

      {/** All products list */}
      <div className="flex items-center flex-wrap gap-5 py-4 h-[calc(100vh-190px)] overflow-y-auto">
        {allProduct.length > 0 ? (
          allProduct.map((product, index) => (
            <AdminProductCard data={product} key={index + "allProduct"} fetchdata={fetchAllProduct} />
          ))
        ) : (
          <p className="w-full text-center text-gray-500">No products found. Upload your first product!</p>
        )}
      </div>

      {/** Upload product component */}
      {openUploadProduct && (
        <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchData={fetchAllProduct} />
      )}
    </div>
  );
};

export default AllProducts;
