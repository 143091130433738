import React, { useState, useEffect, useRef } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { FaTrashAlt } from "react-icons/fa";

const UploadGallery = () => {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(''); // URL input state
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]); // Store fetched files
  const fileInputRef = useRef(null); // Ref for the file input element
  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    fetchFiles();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const fetchFiles = async () => {
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/files/${user?._id}`);
      const data = await response.json();
      if (response.ok) {
        setFiles(data);
        toast.success('Files loaded successfully! 🎉');
      } else {
        toast.error('Failed to load files.');
      }
    } catch (error) {
      toast.error('Error fetching files.');
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file && !url) {
      toast.error('Please select a file or provide a URL!');
      return;
    }

    const formData = new FormData();
    if (file) formData.append('file', file);
    if (url) formData.append('url', url);

    setLoading(true);

    try {
      const response = await fetch('https://builder.biztoindia.in/api/upload', {
        credentials: 'include',
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (response.ok) {
        toast.success('File uploaded successfully! 🎉');
        fileInputRef.current.value = ''; // Reset the file input field
        setFile(null);
        setUrl('');
        fetchFiles(); // Refresh the list of files after successful upload
      } else {
        toast.error('File upload failed! ' + (data.message || 'Please try again.'));
      }
    } catch (error) {
      toast.error('Oops! Something went wrong during the upload.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this file?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://builder.biztoindia.in/api/gallery/delete/${id}`, {
        credentials: 'include',
        method: 'DELETE'
      });
      const data = await response.json();
      if (response.ok) {
        toast.success('File deleted successfully!');
        fetchFiles();
      } else {
        toast.error('Failed to delete the file.');
      }
    } catch (error) {
      toast.error('Error deleting file.');
    }
  };

  // Helper function to check if the URL is a YouTube link
  const isYouTubeUrl = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
  };

  // Helper function to get the embeddable YouTube URL
  const getYouTubeEmbedUrl = (url) => {
    const regExp = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? `https://www.youtube.com/embed/${match[1]}` : url;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <Toaster position="top-center" reverseOrder={false} />
      <h1 className="text-4xl font-bold mb-2 text-gray-800">Upload to Gallery</h1>
      <p className="text-lg text-gray-600 mb-6 text-center">
        Upload images or videos to showcase your creative websites. 
        Let’s make this gallery amazing!
      </p>
      <form className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg border border-gray-300" onSubmit={handleUpload}>
        <label className="block text-gray-700 font-semibold mb-2">
          Choose a file:
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-500 border border-gray-300 rounded-md p-2 mt-2 focus:ring-2 focus:ring-blue-500 transition duration-200"
            aria-label="File upload"
            accept="image/*,video/*"
          />
        </label>
        <label className="block text-gray-700 font-semibold mb-2">
          Or enter a URL:
          <input
            type="text"
            value={url}
            onChange={handleUrlChange}
            placeholder="Enter a valid URL"
            className="block w-full text-sm text-gray-500 border border-gray-300 rounded-md p-2 mt-2 focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
        </label>
        <p className="text-gray-600 text-sm mb-4">
          *Please upload only images (JPG, PNG,  ) or provide a valid URL.
        </p>
        <button
          type="submit"
          className={`w-full py-2 px-4 mt-4 text-white font-semibold rounded-md ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 transition duration-200'}`}
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>

      {/* Display uploaded files */}
      <div className="mt-8 w-full max-w-4xl bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-700 mb-4">Gallery</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {files.map((file) => (
            <div key={file._id} className="relative">
              <div className="aspect-w-1 aspect-h-1">
                {file.mimeType?.startsWith('image/') ? (
                  <img src={`https://builder.biztoindia.in/${file.path}`} alt={file.originalName} className="w-full h-full object-cover rounded-md" />
                ) : file.url && isYouTubeUrl(file.url) ? (
                  <iframe
                    src={getYouTubeEmbedUrl(file.url)}
                    className="w-full h-full object-cover rounded-md"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube Video"
                  ></iframe>
                ) : file.mimeType?.startsWith('video/') ? (
                  <video src={`https://builder.biztoindia.in/${file.path}`} controls className="w-full h-full object-cover rounded-md" />
                ) : (
                  <a href={file.url} target="_blank" rel="noopener noreferrer" className="w-full h-full object-cover rounded-md">
                    Open Link
                  </a>
                )}
              </div>
              <button
                onClick={() => handleDelete(file._id)}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full focus:outline-none"
              >
                <FaTrashAlt />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UploadGallery;
