import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const GrantingPermission = ({ userId }) => {
  const [permissions, setPermissions] = useState({
    showFaq: false,
    showPrivacy: false,
    showShippingPage: false,
    showTermofUsePage: false,
    showRefundPage: false,
    showBlogs: false,
    showTestimonialsPage: false,
    showGalleryPage: false,
    showClientPage: false,
  });
  
  const user = useSelector((state) => state?.user?.user);
  
  // Define the permissions options
  const permissionOptions = [
    { name: 'showFaq', label: 'Show FAQ' },
    { name: 'showPrivacy', label: 'Show Privacy Policy' },
    { name: 'showShippingPage', label: 'Show Shipping Page' },
    { name: 'showTermofUsePage', label: 'Show Term of Use Page' },
    { name: 'showRefundPage', label: 'Show Refund Page' },
    { name: 'showBlogs', label: 'Show Blogs' },
    { name: 'showTestimonialsPage', label: 'Show Testimonials Page' },
    { name: 'showGalleryPage', label: 'Show Gallery Page' },
    { name: 'showClientPage', label: 'Show Client Page' },
  ];

  // Fetch existing permissions
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/permission-get/${user?._id}`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch permissions');
        }

        const data = await response.json();
        console.log("data",data);
        setPermissions(data.permissions); // Assuming `data.permissions` returns the permissions object
      } catch (error) {
        console.error('Error fetching permissions:', error);
        toast.error('Failed to fetch permissions. Please try again.');
      }
    };

    fetchPermissions();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    setPermissions((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://builder.biztoindia.in/api/permission-provider', {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(permissions),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success('Permissions updated successfully!');
    } catch (error) {
      toast.error('Failed to update permissions. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-600">Manage Your Permissions</h2>
        <p className="text-gray-600 mb-6 text-center">
          Select the features you want to enable for your account. Your choices help customize your experience.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          {permissionOptions.map(({ name, label }) => (
            <div className="flex items-center p-2 border border-gray-300 rounded-md hover:bg-blue-50 transition duration-200" key={name}>
              <input
                type="checkbox"
                name={name}
                id={name}
                checked={permissions[name]}
                onChange={handleInputChange}
                className="mr-3 h-6 w-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor={name} className="text-gray-700 text-lg">
                {label}
              </label>
            </div>
          ))}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Update Permissions
          </button>
        </form>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: '16px',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          },
        }}
      />
    </div>
  );
};

export default GrantingPermission;
